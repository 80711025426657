<template>
  <v-card class="py-2 px-0">
    <v-card-title class="font-weight-regular py-0 pr-2 pl-3">
      <span>
        <span
          v-text="$store.state.notificaciones.length === 0 ? 'No hay' : `Tienes ${$store.state.notificaciones.length}`"
        ></span>
        <span
          v-text="$store.state.notificaciones.length === 1 ? ' notificación' : ' notificaciones'"
        ></span>
      </span>
      <v-spacer></v-spacer>
      <v-btn
        title="Refrescar"
        :loading="load"
        icon
        @click="refrescar"
      >
        <v-icon small>fas fa-sync-alt</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="py-2 px-0">
      <v-card
        v-if="$store.state.notificaciones.length > 0"
        max-height="300"
        class="pb-2"
        style="overflow-y: auto"
        flat
      >
        <v-hover
          v-for="(item, index) in $store.state.notificaciones"
          :key="index"
          v-slot="{ hover }"
        >
          <v-card
            :elevation="hover ? 4 : 0"
            :class="hover ? 'my-1 mx-2 primary' : 'my-1 mx-3'"
            outlined
            @click="ver(item)"
          >
            <v-card-text
              class="d-flex pa-2"
              :class="hover ? 'white--text' : ''"
            >
              <v-icon
                :color="hover ? 'white' : item.color"
                small
                left
              >
                {{ item.icono }}
              </v-icon>
              <div>
                {{ item.titulo }}
              </div>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-card>
    </v-card-text>
    <v-card-actions class="d-flex justify-end mx-2">
      <v-btn
        color="info"
        to="/notificaciones"
        x-small
      >
        <v-icon class="mr-2 ml-1" x-small>fas fa-share</v-icon>
        Ver todas
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data () {
    return {
      load: false,
    }
  },
  methods: {
    async ver (item) {
      // muestra el mensaje de la notificacion en un modal
      this.$swal.fire({
        icon: 'info',
        title: item.titulo,
        text: item.mensaje,
      })
        .then(async () => {

          // marca la notificacion como leida cuando cierra el modal
          await this.$store.dispatch('marcar_notificacion_leida', { id: item.id })
            .then(() => {

              // elimina la notificacion del local storege
              this.$store.commit('delete_notificacion', item)
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error',
              })
            })

        })
    },
    async refrescar () {
      this.load = true
      await this.$store.dispatch('get_notificaciones_noleidas')
      this.load = false
    }
  }
}
</script>