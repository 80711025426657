<template>
  <v-app-bar
    color="primary"
    style="z-index: 2"
    :flat="$vuetify.breakpoint.mdAndUp"
    dense
    dark
    app
  >
    <v-app-bar-nav-icon
      @click.stop="$vuetify.breakpoint.smAndDown ? drawer = !drawer : expand = !expand"
    ></v-app-bar-nav-icon>

    <v-toolbar-title style="cursor: default;" class="pl-0">
      <span :style="esPantallaChica ? 'font-size: 16px;' : 'font-size:18px;' ">
      {{
        $store.state.menu_nombres.find(item => item.ruta === $route.path) ?
        $store.state.menu_nombres.find(item => item.ruta === $route.path).nombre :
        $route.name
      }}
      </span>
    </v-toolbar-title>

    <v-spacer></v-spacer>
    
    <!-- Menú rapido -->
    <div class="d-flex justify-end pa-1" v-if="!esPantallaChica">
      <v-tooltip bottom 
        v-for="(item, index) in $store.state.menu_rapido" :key="index"
      >
        <template v-slot:activator="{ on, attrs }" v-if="index <=5 ">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            @click="cambiarPestania(item.ruta_web)"
          >
            <v-icon>{{ item.icono }}</v-icon>
          </v-btn>
        </template>
        <span>{{ item.nombre }}</span>
      </v-tooltip>
    </div>
    <v-menu offset-y bottom  v-if="esPantallaChica && $store.state.menu_rapido!=0">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-on="on"
          v-bind="attrs"
          icon small
          title="Accesos"
          @click="sheet = !sheet"
        >
            <v-icon small>fas fa-caret-down</v-icon>
        </v-btn>
      </template>
      <v-list class="py-2">
        <v-list-item
          class="my-n1"
          style="cursor: pointer"
          @click="cambiarPestania(item.ruta_web)"
            v-for="(item, index) in $store.state.menu_rapido" :key="index"
        >
          <v-list-item-title>
            <v-icon left small>{{ item.icono }}</v-icon>
            {{ item.nombre }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-divider class="" vertical :dark="$vuetify.theme.dark ? true: false"></v-divider>

    <div class="pl-1 d-flex">
      <v-menu class=""
        max-width="300"
        transition="scale-transition"
        :origin="$vuetify.breakpoint.smAndDown ? 'center top' : 'right top'"
        :nudge-width="250"
        :nudge-left="$vuetify.breakpoint.smAndDown ? 0 : 250"
        :disabled="$route.name === 'Notificaciones'"
        :close-on-content-click="false"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            title="Notificaciones"
            :disabled="$route.name === 'Notificaciones'"
            icon :small="esPantallaChica"
          >
            <v-badge
              :content="$store.state.notificaciones.length"
              :value="$store.state.notificaciones.length"
              color="error"
              overlap
            >
              <v-icon :small="esPantallaChica">fas fa-bell</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <ModalNotificaciones />
      </v-menu>

      <v-menu offset-y left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            title="Ajustes"
            v-bind="attrs"
            v-on="on"
            icon :small="esPantallaChica"
          >
            <v-icon :small="esPantallaChica">fas fa-user-cog</v-icon>
          </v-btn>
        </template>
        <v-list class="py-2">
          <!--  -->
          <v-list-item
            class="my-n1"
            style="cursor: pointer"
            @click="set_theme"
            :loading="load"
          >
            <v-list-item-title>
              <v-icon small class="pr-2">fas fa-{{ $vuetify.theme.dark ? 'sun' : 'moon' }}</v-icon>
              {{ $vuetify.theme.dark ? 'Tema claro' : 'Tema oscuro' }}
            </v-list-item-title>
          </v-list-item>
          <!--  -->
          <v-list-item
            class="my-n1"
            style="cursor: pointer"
            @click.stop="dialog_clave = true"
          >
            <v-list-item-title>
              <v-icon left small>fas fa-key</v-icon>
              Cambiar clave
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            class="my-n1"
            style="cursor: pointer"
            @click.stop="dialog_perfil = true"
          >
            <v-list-item-title>
              <v-icon left small>fas fa-user-circle</v-icon>
              Foto de perfil
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            class="my-n1"
            style="cursor: pointer"
            @click.stop="sincronizar()"
          >
            <v-list-item-title>
              <v-icon left small>fas fa-sync</v-icon>
              Sincronizar
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <div
        class="d-flex align-center font-weight-medium px-2"
        style="font-size: 14px; letter-spacing: 1.374px; cursor: default;"
        :icon="$vuetify.breakpoint.smAndDown"
      >
        <v-avatar :size="esPantallaChica ? '30' : '36'">
          <v-img :src="$store.state.img_perfil === '' ? require('./assets/bb.webp') : `data:image/jpeg;base64,${$store.state.img_perfil}`"></v-img>
        </v-avatar>
        <div v-if="!$vuetify.breakpoint.smAndDown" class="ml-2">{{ $store.state.username }}</div>
      </div>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon :small="esPantallaChica"
            @click="logout"
          >
            <v-icon :small="esPantallaChica">fas fa-sign-out-alt</v-icon>
          </v-btn>
        </template>
        <span>Cerrar sesión</span>
      </v-tooltip>
    </div>

    <CambiarClave
      v-model="dialog_clave"
      title="Cambiar clave"
      text="Por favor ingresar una nueva clave:"
      :user="$store.state.username"
    />

    <CambiarImgPerfil
      v-model="dialog_perfil"
    />

  </v-app-bar>
</template>

<script>
import CambiarClave from './components/CambiarClave'
import CambiarImgPerfil from './components/CambiarImgPerfil'
import ModalNotificaciones from './components/ModalNotificaciones'

export default {
  data () {
    return {
      dialog_clave: false,
      dialog_perfil: false,
      load: false,
      esPantallaChica: this.$vuetify.breakpoint.xsOnly,
      sheet: false,
    }
  },
  created () {
    this.$vuetify.theme.dark = JSON.parse(localStorage.getItem('dark'))
  },
  props: {
    p_expand: Boolean,
    p_drawer: Boolean,
  },
  components: {
    CambiarClave,
    CambiarImgPerfil,
    ModalNotificaciones,
  },
  computed: {
    expand: {
      get () {
        return this.p_expand
      },
      set (value) {
        this.$emit('update:p_expand', value)
      }
    },
    drawer: {
      get () {
        return this.p_drawer
      },
      set (value) {
        this.$emit('update:p_drawer', value)
      }
    },
  },
  methods: {
    async sincronizar () {
      this.$store.state.loading = true
      await this.$store.dispatch('sincronizar')
        .then((res) => {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success',
          })
          window.location.reload(true)
        })
        .catch((error) => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.$store.state.loading = false
    },
    async set_theme () {
      this.load = true
      await this.$store.dispatch('set_theme', !this.$vuetify.theme.dark)
        .then((res) => {
          this.$vuetify.theme.dark = !this.$vuetify.theme.dark
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success',
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.load = false
    },
    logout () {
      this.$store.dispatch('logout', true)
      this.$vuetify.theme.dark = false
    },
    cambiarPestania(ruta){
      let path = `${ruta}`
      const routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
    }
  },
  watch: {
    '$vuetify.breakpoint.xsOnly' (val) {
      this.esPantallaChica = val;
    }
  }
}
</script>