import config from '../../config'
import store from '../store'


export default {
  namespaced: true,
  state: {
    estados_pedido: [],
    stock_categorias: [],
    stock_depositos: [],
    stock_entidades: [],
    stock_periodos: [],
    stock_regiones: [],
    stock_rubros: []
  },
  mutations: {
    set_estados_pedido (state, payload) {
      state.estados_pedido = payload
    },
    setStockCateg(state, payload){
      state.stock_categorias = payload
      localStorage.setItem('stock_categorias', JSON.stringify(payload))
    },
    setStockDepositos(state, payload){
      state.stock_depositos = payload
      localStorage.setItem('stock_depositos', JSON.stringify(payload))
    },
    setStockEntidades(state, payload){
      state.stock_entidades = payload
      localStorage.setItem('stock_entidades', JSON.stringify(payload))
    },
    setStockPeriodos(state, payload){
      state.stock_periodos = payload
      localStorage.setItem('stock_periodos', JSON.stringify(payload))
    },
    setStockRegiones(state, payload){
      state.stock_regiones = payload
      localStorage.setItem('stock_regiones', JSON.stringify(payload))
    },
    setStockRubros(state, payload){
      state.stock_rubros = payload
      localStorage.setItem('stock_rubros', JSON.stringify(payload))
    }
  },
  actions: {
    async getArticuloInfo({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let controlPeticion = await fetch(`${config.BASE_URL}/adminStock/getArticuloInfo?articulo=${datos.articulo}&tipo=${datos.tipo}&bodega=${datos.bodega}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let control = await controlPeticion.json()
          control.msj = control.message
          return resolve(control)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getArticuloInfo: ' + error.message
          })
        }
      })
    },
    async checkStockBodega({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let controlPeticion = await fetch(`${config.BASE_URL}/adminStock/checkStockBodArt?bodegaOrigen=${datos.bodegaOrigen}&bodegaDestino=${datos.bodegaDestino}&articulo_codigo=${datos.articulo_codigo}&cantidad=${datos.cantidad}&mostrar_precio=${datos.mostrar_precio}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let control = await controlPeticion.json()
          control.msj = control.message
          return resolve(control)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo checkStockBodega: ' + error.message
          })
        }
      })
    },
    async validateCargaAsistida({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let validarPeticion = await fetch(`${config.BASE_URL}/adminStock/validateCargaAsistida`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let validar = await validarPeticion.json()
          validar.msj = validar.message
          return resolve(validar)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo validateCargaAsistida: ' + error.message
          })
        }
      })
    },
    async getSeriesInfo({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let infoPeticion = await fetch(`${config.BASE_URL}/adminStock/getSeriesInfo`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let info = await infoPeticion.json()
          info.msj = info.message
          return resolve(info)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getSeriesInfo: ' + error.message
          })
        }
      })
    },
    async initFormDepositos({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let initPeticion = await fetch(`${config.BASE_URL}/adminStock/initFormDepositos`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let init = await initPeticion.json()
          init.msj = init.message
          return resolve(init)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo initFormDepositos: ' + error.message
          })
        }
      })
    },
    async getDepositos({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let depositosPeticion = await fetch(`${config.BASE_URL}/adminStock/getDepositos?empresa_codigo=${datos.empresa_codigo}&sucursal_codigo=${datos.sucursal_codigo}&tipo_dep_codigo=${datos.tipo_dep_codigo}&nombre_deposito=${datos.nombre_deposito}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let dep = await depositosPeticion.json()
          dep.msj = dep.message
          return resolve(dep)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getDepositos: ' + error.message
          })
        }
      })
    },
    async setEstadoDeposito({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let estadoPeticion = await fetch(`${config.BASE_URL}/adminStock/setEstadoDeposito`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let estado = await estadoPeticion.json()
          estado.msj = estado.message
          return resolve(estado)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo setEstadoDeposito: ' + error.message
          })
        }
      })
    },
    async getLocalesDepositos({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let localesPeticion = await fetch(`${config.BASE_URL}/adminStock/getLocalesDepositos?empresa_codigo=${datos.empresa_codigo}&sucursal_codigo=${datos.sucursal_codigo}&local_codigo=${datos.local_codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let locales = await localesPeticion.json()
          locales.msj = locales.message
          return resolve(locales)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getLocalesDepositos: ' + error.message
          })
        }
      })
    },
    async updateDeposito({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let updatePeticion = await fetch(`${config.BASE_URL}/adminStock/updateDeposito`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let update = await updatePeticion.json()
          update.msj = update.message
          return resolve(update)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo updateDeposito: ' + error.message
          })
        }
      })
    },
    async newDeposito({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let nuevoPeticion = await fetch(`${config.BASE_URL}/adminStock/newDeposito`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let nuevo = await nuevoPeticion.json()
          nuevo.msj = nuevo.message
          return resolve(nuevo)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo newDeposito: ' + error.message
          })
        }
      })
    },
    async get_etiquetas_precio ({}, filtro) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/adminStock/getEtiquetasPrecio`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(filtro)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_estados_pedido ({ state, commit }) {
      try {
        if (state.estados_pedido.length == 0) {
          const res = await fetch(`${config.BASE_URL}/adminStock/estadosPedido`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_estados_pedido', data.data)
          } else {
            commit('set_estados_pedido', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_estados_pedido', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_pedidos ({}, filtro) {
      return await new Promise(async (resolve, reject) => {
        try {
          const sucursal = filtro.sucursal
          const local = filtro.local
          const desde = filtro.desde
          const hasta = filtro.hasta
          const numero = filtro.numero
          const estado = filtro.estado
          const res = await fetch(`${config.BASE_URL}/adminStock/pedidos?sucursal=${sucursal ? sucursal : ''}&local=${local ? local : ''}&desde=${desde ? desde : ''}&hasta=${hasta ? hasta : ''}&numero=${numero ? numero : ''}&estado=${estado ? estado : ''}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_detalles_pedido ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/adminStock/detallesPedido?numero=${id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_permiso_pedido () {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/adminStock/permisoPedido`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito != 1) {
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
          resolve(data.permiso == 1 ? true : false)
    
        } catch (error) {
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
          resolve(false)
        }
      })
    },
    async liberar_pedido ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/adminStock/liberarPedido`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ numero: id })
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async tomar_pedido ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/adminStock/tomarPedido`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ numero: id })
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async editar_pedido ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/adminStock/editarPedido`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ numero: id })
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async anular_pedido ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/adminStock/anularPedido`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ numero: id })
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async nuevo_pedido ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/adminStock/nuevoPedido`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async modificar_pedido ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/adminStock/modificarPedido`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async aceptar_pedido ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/adminStock/aceptarPedido`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async getArticulosReg ({}, region) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/adminStock/articulosRegion?region=${region}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    /*
      RODRIGO
    */
    async initStockDisponible({commit}){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/adminStock/initStockDisponible`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    async getStockDepositos({}, depositos){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/adminStock/getStockDepositos`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(depositos)
          })
          const data = await res.json();
          
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    async getStockDisponible({}, payload){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/adminStock/getStockDisponible`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
          const data = await res.json();

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    async getStockSeriesDisponibles({}, item){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/adminStock/getStockSeriesDisponibles?deposito_codigo=${item.deposito}&articulo_codigo=${item.articulo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();
          
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      })      
    },
    async initGestionStock({commit}){
      return await new Promise(async (resolve, reject) => {
        try{
          let initPeticion = await fetch(`${config.BASE_URL}/adminStock/initGestionStock`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let init = await initPeticion.json()
          init.msj = init.message
          // guardo en localStorage
          if (init.resultado == 1){
            commit('setStockCateg', init.categorias)
            commit('setStockDepositos', init.depositos)
            commit('setStockEntidades', init.entidades)
            commit('setStockPeriodos', init.periodos)
            commit('setStockRegiones', init.regiones)
            commit('setStockRubros', init.rubros)
          }
          return resolve(init)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo initGestionStock: ' + error.message
          })
        }
      })
    },
    async getSucursalesCercanas({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let sucuPeticion = await fetch(`${config.BASE_URL}/adminStock/getSucursalesCercanas?sucursal_codigo=${datos.sucursal_codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let sucu = await sucuPeticion.json()
          sucu.msj = sucu.message
          return resolve(sucu)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getSucursalesCercanas: ' + error.message
          })
        }
      })
    },
    async getGestionStockDets({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let detsPeticion = await fetch(`${config.BASE_URL}/adminStock/getGestionStockDets`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let dets = await detsPeticion.json()
          dets.msj = dets.message
          return resolve(dets)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getGestionStockDets: ' + error.message
          })
        }
      })
    },
    async getGestionStockXArticulo({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let stockPeticion = await fetch(`${config.BASE_URL}/adminStock/getGestionStockXArticulo`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let stock = await stockPeticion.json()
          stock.msj = stock.message
          return resolve(stock)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getGestionStockXArticulo: ' + error.message
          })
        }
      })
    }
  }
}