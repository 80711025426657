<template>
  <v-dialog
    v-model="dialog"
    max-width="1000"
    :fullscreen="fullscreen"
    :loading="load"
    scrollable
  >
    <v-card>
      <v-card-title>
        {{ nombre }}
        <v-spacer></v-spacer>
        <v-btn
          class="mr-2"
          title="Rotar -90°"
          icon
          @click="rotar -= 90"
        >
          <v-icon>fas fa-undo</v-icon>
        </v-btn>
        <v-btn
          class="mr-4"
          title="Rotar +90°"
          icon
          @click="rotar += 90"
        >
          <v-icon>fas fa-redo</v-icon>
        </v-btn>
        <v-btn
          class="mr-2"
          title="Alejar zoom"
          icon
          @click="$refs.zoomer.zoomOut()"
        >
          <v-icon>fas fa-search-minus</v-icon>
        </v-btn>
        <v-btn
          class="mr-4"
          title="Aumentar zoom"
          icon
          @click="$refs.zoomer.zoomIn()"
        >
          <v-icon>fas fa-search-plus</v-icon>
        </v-btn>
        <v-btn
          class="mr-2"
          :title="fullscreen ? 'Minimizar' : 'Maximizar'"
          icon
          @click="fullscreen = !fullscreen"
        >
          <v-icon>fas fa-window-{{ fullscreen ? 'restore' : 'maximize' }}</v-icon>
        </v-btn>
        <v-btn
          title="Cerrar"
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text
        class="pa-0 d-flex justify-center"
      >
        <!-- <v-img
          :src="img"
          max-height="500"
          max-width="1000"
          contain
        ></v-img> -->
        <v-zoomer v-if="!load" ref="zoomer" pivot="cursor" :style="fullscreen ? `width: ${dims.ancho*2}px;` : `width: ${dims.ancho}px;`" :mouseWheelToZoom="false">
          <img
            :src="img"
            :style="`transform: rotate(${rotar}deg);`"
            style="object-fit: contain; width: 100%; height: 100%;"
          >
        </v-zoomer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { getDims } from '../../util/utils'

export default {
  data () {
    return {
      rotar: 0,
      fullscreen: false,
      load: false,
      zoom: false,
      dims: {
        ancho: 0,
        alto: 0
      }
    }
  },
  props: {
    value: Boolean,
    img: String,
    nombre: String,
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    async dialog (val) {
      if (!val) {
        this.$refs.zoomer.reset()
        this.fullscreen = false
      }
      else {
        this.load = true
        this.dims = await getDims(this.img)
        this.load = false
      }
    }
  }
}
</script>