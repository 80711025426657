import config from "../../../config"
import store from '../../store'
import { Encrypt, Decrypt } from '../../util/aes.js'
import { VueEasyJwt } from 'vue-easy-jwt'
const jwt = new VueEasyJwt()
export default{
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    async getRepartidor({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let body = {
            usuario: store.state.username
          }
          let repartidorPeticion = await fetch(config.BASE_URL + `/adminRepartidores/getRepartidor`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(body)
          })
          let repartidor = await repartidorPeticion.json()
          repartidor.msj = repartidor.message
          return resolve(repartidor)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getRepartidor: ' + error.message,
            repartidor: null
          })
        }
      })
    },
    async aceptarPaquete({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let aceptarPeticion = await fetch(config.BASE_URL + `/adminRepartidores/aceptarPaquete`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let aceptar = await aceptarPeticion.json()
          aceptar.msj = aceptar.message
          return resolve(aceptar)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo aceptarPaquete: ' + error.message
          })
        }
      })
    },
    async generarDevolucion({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let devolverPeticion = await fetch(config.BASE_URL + `/adminRepartidores/generarDevolucion`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let devolver = await devolverPeticion.json()
          devolver.msj = devolver.message
          return resolve(devolver)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo generarDevolucion: ' + error.message
          })
        }
      })
    },
    async validarCtaCorriente({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let saldosPeticion = await fetch(config.BASE_URL + `/adminRepartidores/validarCtaCorriente`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let saldos = await saldosPeticion.json()
          if (saldos.resultado == 1){
            return resolve({
              resultado: 1,
              msj: 'OK',
              saldos: saldos.saldos
            })
          }else{
            return resolve({
              resultado: 0,
              msj: saldos.message,
              saldos: null
            })
          }
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo validarCtaCorriente: ' + error.message,
            saldos: null
          })
        }
      })
    },
    async entregarPaquete({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let entregaPeticion = await fetch(config.BASE_URL + `/adminRepartidores/entregarPaquete`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let entrega = await entregaPeticion.json()
          entrega.msj = entrega.message
          return resolve(entrega)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo entregarPaquete: ' + error.message
          })
        }
      })
    },
    async rechazarPaquete({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let rechazoPeticion = await fetch(config.BASE_URL + `/adminRepartidores/rechazarPaquete`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let rechazo = await rechazoPeticion.json()
          rechazo.msj = rechazo.message
          return resolve(rechazo)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo rechazarPaquete: ' + error.message
          })
        }
      })
    },
    async getPaquetesDevolucion({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let paquetesPeticion = await fetch(config.BASE_URL + `/adminRepartidores/getPaquetesDevolucion?repartidor_id=${datos.repartidor_id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let paquetes = await paquetesPeticion.json()
          paquetes.msj = paquetes.message
          return resolve(paquetes)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getPaquetesDevolucion: ' + error.message
          })
        }
      })
    },
    async devolucionMasiva({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let devolucionPeticion = await fetch(config.BASE_URL + `/adminRepartidores/devolucionMasiva`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let devolucion = await devolucionPeticion.json()
          devolucion.msj = devolucion.message
          return resolve(devolucion)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo devolucionMasiva: ' + error.message
          })
        }
      })
    }
  }
}