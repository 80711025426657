import config from '../../config'
import store from '../store'

export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    async initIndexForm({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let initPeticion = await fetch(`${config.BASE_URL}/adminRemitosDevolucion/initIndexDevoluciones`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let init = await initPeticion.json()
          init.msj = init.message
          return resolve(init)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo initIndexForm: ' + error.message
          })
        }
      })
    },
    async getRemitosDevolucion({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let devolucionesPeticion = await fetch(`${config.BASE_URL}/adminRemitosDevolucion/getRemitosDevolucion?sucursal=${datos.sucursal}&ptoVta=${datos.ptoVta}&estado=${datos.estado}&cliente=${datos.cliente}&fecha_desde=${datos.fecha_desde}&fecha_hasta=${datos.fecha_hasta}&codigo=${datos.codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let devoluciones = await devolucionesPeticion.json()
          devoluciones.msj = devoluciones.message
          return resolve(devoluciones)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getRemitosDevolucion: ' + error.message,
            devoluciones: null
          })
        }
      })
    },
    async getVendedoresMayoristasXPtoVta({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let vendedoresPeticion = await fetch(`${config.BASE_URL}/adminRemitosDevolucion/getVendedoresMayoristasXPtoVta?pto_vta=${datos.pto_vta}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let vendedores = await vendedoresPeticion.json()
          vendedores.msj = vendedores.message
          return resolve(vendedores)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getVendedoresMayoristasXPtoVta: ' + error.message,
            vendedores: null
          })
        }
      })
    },
    async getDetallesDevolucion({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let detallesPeticion = await fetch(`${config.BASE_URL}/adminRemitosDevolucion/getDetallesDevolucion?devolucion=${datos.devolucion}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let detalles = await detallesPeticion.json()
          detalles.msj = detalles.message
          return resolve(detalles)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getDetallesDevolucion: ' + error.message,
            detalles: null
          })
        }
      })
    },
    async initNuevaDevolucionForm({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let initPeticion = await fetch(`${config.BASE_URL}/adminRemitosDevolucion/initNuevaDevolucionForm`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let init = await initPeticion.json()
          init.msj = init.message
          return resolve(init)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo initNuevaDevolucionForm: ' + error.message
          })
        }
      })
    },
    async getArtsDevolucion({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let artPeticion = await fetch(`${config.BASE_URL}/adminRemitosDevolucion/getArticuloDevolucion?articulo_codigo=${datos.articulo_codigo}&cliente=${datos.cliente}&lista=${datos.lista}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let art = await artPeticion.json()
          art.msj = art.message
          return resolve(art)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getArtsDevolucion: ' + error.message
          })
        }
      })
    },
    async grabarDevolucion({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let devolucionPeticion = await fetch(`${config.BASE_URL}/adminRemitosDevolucion/grabarDevolucion`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let devolucion = await devolucionPeticion.json()
          devolucion.msj = devolucion.message
          return resolve(devolucion)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo grabarDevolucion: ' + error.message
          })
        }
      })
    },
    async initEditDevolucion({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let initPeticion = await fetch(`${config.BASE_URL}/adminRemitosDevolucion/initEditDevolucion?codigo=${datos.codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let init = await initPeticion.json()
          init.msj = init.message
          return resolve(init)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo initEditDevolucion: ' + error.message
          })
        }
      })
    },
    async modificarDevolucion({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let devolucionPeticion = await fetch(`${config.BASE_URL}/adminRemitosDevolucion/modificarDevolucion`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let devolucion = await devolucionPeticion.json()
          devolucion.msj = devolucion.message
          return resolve(devolucion)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo modificarDevolucion: ' + error.message
          })
        }
      })
    },
    async aceptarDevolucion({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let aceptarPeticion = await fetch(`${config.BASE_URL}/adminRemitosDevolucion/aceptarDevolucion`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let aceptar = await aceptarPeticion.json()
          aceptar.msj = aceptar.message
          return resolve(aceptar)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo aceptarDevolucion: ' + error.message
          })
        }
      })
    },
    async auditarDevolucion({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let auditarPeticion = await fetch(`${config.BASE_URL}/adminRemitosDevolucion/auditarDevolucion`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let auditar = await auditarPeticion.json()
          auditar.msj = auditar.message
          return resolve(auditar)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo auditarDevolucion: ' + error.message
          })
        }
      })
    },
    async rechazarDevolucion({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let rechazoPeticion = await fetch(`${config.BASE_URL}/adminRemitosDevolucion/rechazarDevolucion`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let rechazo = await rechazoPeticion.json()
          rechazo.msj = rechazo.message
          return resolve(rechazo)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo rechazarDevolucion: ' + error.message
          })
        }
      })
    }
  }
}