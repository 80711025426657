import config from '../../config'
import store from '../store'
import { formatQuery } from '../util/utils'

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    // OPERACIONES LAPOS
    async enviar_pago ({}, solicitud_id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/laposIntegrado/enviarPagoLapos`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ solicitud_cobro_id: solicitud_id })
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async cancelar_pago ({}, solicitud_id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/laposIntegrado/cancelarPagoLapos`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ solicitud_cobro_id: solicitud_id })
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async enviar_anular ({}, solicitud_id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/laposIntegrado/enviarAnulacionLapos`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ solicitud_cobro_id: solicitud_id })
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async cancelar_anular ({}, solicitud_id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/laposIntegrado/cancelarAnulacionLapos`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ solicitud_cobro_id: solicitud_id })
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async actualizar ({}, solicitud_id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/laposIntegrado/actualizarEstado`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ solicitud_cobro_id: solicitud_id })
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async get_estado({}, solicitud_id){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/laposIntegrado/getEstado?solicitud_id=${solicitud_id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async set_anular_oracle({}, solicitud_id){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/laposIntegrado/anularSolicitudCobro`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ solicitud_cobro_id: solicitud_id })
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async get_payway_status({}, empresa_codigo){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/laposIntegrado/getStatusPayway?empresa_codigo=${empresa_codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    // CAJA
    async update_caja ({}, cj_numero) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/laposIntegrado/sendUpdateCajaLapInt`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ caja_jerarquia_numero: cj_numero })
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    // CIERRE LOTE
    async get_cierre_lote ({}, ptovta_cod) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/laposIntegrado/getCierresLapos?pto_vta=${ptovta_cod}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()

          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async enviar_cierre_lote ({}, num_terminal) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/laposIntegrado/sendCierreLote`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ terminal: num_terminal })
          })
    
          const data = await res.json()

          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async actualizar_cierre_lote ({}, num_terminal) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/laposIntegrado/syncCierreLote`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ terminal: num_terminal })
          })
    
          const data = await res.json()

          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    // PANEL LAPOS
    async get_permisos () {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/laposIntegrado/permiso`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito != 1) {
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
          resolve({
            admin: data.data.admin == 1 ? true : false,
            permiso: data.data.permiso == 1 ? true : false
          })
    
        } catch (error) {
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
          resolve({
            admin: false,
            permiso: false
          })
        }
      })
    },
    async get_solicitud ({}, num_ped) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/laposIntegrado/solicitudes?num_ped=${num_ped}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_transacciones ({}, sol_cobro_id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/laposIntegrado/transacciones?sol_cobro_id=${sol_cobro_id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    // PANEL CIERRE LOTE
    async get_permiso_cupon () {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/laposIntegrado/permisoCupones`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito != 1) {
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
          resolve(data.permiso == 1 ? true : false)
    
        } catch (error) {
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
          resolve(false)
        }
      })
    },
    async get_cierres_lote ({}, filtro) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/laposIntegrado/cierresLote?local=${formatQuery(filtro.local)}&terminal=${formatQuery(filtro.terminal)}&desde=${formatQuery(filtro.desde)}&hasta=${formatQuery(filtro.hasta)}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_next_lote ({}, terminal) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/laposIntegrado/nextLote?terminal=${terminal}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data.lote)
          } else {
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
            resolve(null)
          }
    
        } catch (error) {
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
          resolve(null)
        }
      })
    },
    async cargar_cierre_lote ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/laposIntegrado/cargarCierreLote`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    }
  }
}