import config from '../../../config'
import store from '../../store'
import { format_date } from '../../util/utils'

export default {
  namespaced: true,
  state: {
    tipos_control_stk: [],
    tipos_recupero: [],
    estados_control_stk: [],
    estados_control_stk_det: []
  },
  mutations: {
    set_tp_control_stk (state, payload) {
      state.tipos_control_stk = payload
      localStorage.setItem('tp_control_stk', JSON.stringify(payload))
    },
    set_tp_recupero (state, payload) {
      state.tipos_recupero = payload
      localStorage.setItem('tp_recupero', JSON.stringify(payload))
    },
    set_est_control_stk (state, payload) {
      state.estados_control_stk = payload
      localStorage.setItem('est_control_stk', JSON.stringify(payload))
    },
    set_estados_detalle (state, payload) {
      state.estados_control_stk_det = payload
      localStorage.setItem('est_control_stk_det', JSON.stringify(payload))
    }
  },
  actions: {
    async puede_cancelar () {
      // devuelve 1 o 0 si tiene el rol o no
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/puedeCancelar`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data.data)
          } else {
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
            resolve(0)
          }
    
        } catch (error) {
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
          resolve(0)
        }
      })
    },
    async actualizar_recuperos ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/updateRecuperos`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_recuperos ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/recuperos?id=${id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async auditar_control ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/auditarControl`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ id: id })
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async preauditar_control ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/preauditarControl`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ id: id })
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_historial ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/historial`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async articulos_asistidos ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/articulosAsistido`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async agregar_articulos ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/agregarArticulos`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async validar_articulo ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/validarArticulo`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_datos_informe ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/informe?id=${id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async carga_asistida ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/cargaAsistida`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async procesar_archivo ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/procesarArchivo`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async insertar_comentario_serie ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/setComentarioSerie`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    }, 
    async insertar_comentario ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/setComentario`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    }, 
    async inserat_stock ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/setStockReal`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async verificar_curso ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/enCurso?id=${id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async obtener_series ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/series?id=${id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async obtener_articulos ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/articulos?id=${id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async inhabilitar_control ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/inhabilitarControl`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ id: id })
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async finalizar_control ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/finalizarControl`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ id: id })
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async guardar_control ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/guardarControl`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ id: id })
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async continuar_control ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/continuarControl`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ id: id })
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async iniciar_control ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/iniciarControl`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ id: id })
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async verify_permiso_sup () {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/permisoSup`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()

          resolve(data)
    
        } catch (error) {
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
          reject()
        }
      })
    },
    async verify_permiso ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/permiso`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()

          resolve(data)
    
        } catch (error) {
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
          reject()
        }
      })
    },
    async get_control ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/control?id=${id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_controles ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          let filtro = JSON.parse(JSON.stringify(payload))
          if (!filtro.id) {
            filtro.fecha_desde = format_date(filtro.fecha[0], 'DD/MM/YYYY')
            filtro.fecha_hasta = format_date(filtro.fecha[1], 'DD/MM/YYYY')
          } else {
            filtro.fecha_desde = null
            filtro.fecha_hasta = null
          }
          delete filtro.fecha

          const res = await fetch(`${config.BASE_URL}/controlStock/getControles`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(filtro)
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async editar_control ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/editarControl`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async nuevo_control ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/nuevoControl`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async inhabilitar_configuracion ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/inhabilitarConf`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ id: id })
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async habilitar_configuracion ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/habilitarConf`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ id: id })
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async editar_configuracion ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/editarConf`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async nueva_configuracion ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/nuevaConf`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_configuracion ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/conf?id=${id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_configuraciones ({}, filtro) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/getConf`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(filtro)
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_responsables ({}, sucursal) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/responsables?sucursal=${sucursal}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_estados_detalle ({ state, commit }) {
      try {
        if (state.estados_control_stk_det.length == 0) {
          const res = await fetch(`${config.BASE_URL}/controlStock/estadosDetalle`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_estados_detalle', data.data)
          } else {
            commit('set_estados_detalle', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_estados_detalle', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_estados ({ state, commit }) {
      try {
        if (state.estados_control_stk.length == 0) {
          const res = await fetch(`${config.BASE_URL}/controlStock/estados`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_est_control_stk', data.data)
          } else {
            commit('set_est_control_stk', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_est_control_stk', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_tipos_recupero ({ state, commit }) {
      try {
        if (state.tipos_recupero.length == 0) {
          const res = await fetch(`${config.BASE_URL}/controlStock/tiposRecupero`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_tp_recupero', data.data)
          } else {
            commit('set_tp_recupero', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_tp_recupero', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_tipos ({ state, commit }) {
      try {
        if (state.tipos_control_stk.length == 0) {
          const res = await fetch(`${config.BASE_URL}/controlStock/tipos`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_tp_control_stk', data.data)
          } else {
            commit('set_tp_control_stk', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_tp_control_stk', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async validar_codbarra ({}, codigos) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/controlStock/validarArtCodBarra`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ codigos: codigos })
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    }
  }
}