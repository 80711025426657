import config from '../../../config'
import store from '../../store'

export default {
  namespaced: true,
  state: {
    tipos_pedido: [],
    emisiones: [],
    locales: []
  },
  mutations: {
    set_tipos_pedido (state, payload) {
      state.tipos_pedido = payload
    },
    set_emisiones (state, payload) {
      state.emisiones = payload
    },
    set_locales (state, payload) {
      state.locales = payload
    }
  },
  actions: {
    async get_proveedores_bejerman({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let provPeticion = await fetch(`${config.BASE_URL}/bejermanAdmin/getProveedoresBejerman?empresa_codigo=${datos.empresa_codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
          })
          let proveedores = await provPeticion.json()
          return resolve({
            resultado: proveedores.resultado,
            msj: proveedores.message,
            proveedoresBejerman: proveedores.provBejerman
          })
        }catch(error){
          return reject({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo get_proveedores_bejerman: ' + error.message,
            proveedoresBejerman: null
          })
        }
      })
    },
    async get_proveedores_conceptos({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let provConcepPeticion = await fetch(`${config.BASE_URL}/bejermanAdmin/getProveedoresConceptos?empresa_codigo=${datos.empresa_codigo}&proveedor_codigo=${datos.proveedor_codigo}&estado_codigo=${datos.estado}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
          })
          let provConceptos = await provConcepPeticion.json()
          return resolve({
            resultado: provConceptos.resultado,
            msj: provConceptos.message,
            conceptosProv: provConceptos.conceptosProveedores
          })
        }catch(error){
          return reject({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo get_proveedores_conceptos: ' + error.message,
            conceptosProv: null
          })
        }
      })
    },
    async update_estado_concepto_proveedor({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let updatePeticion = await fetch(`${config.BASE_URL}/bejermanAdmin/updateEstadoProvConcepto`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({
              empresa_codigo: datos.empresa_codigo,
              proveedor_codigo: datos.proveedor_codigo,
              concepto_codigo: datos.concepto_codigo,
              new_estado: datos.estado
            })
          })
          let update = await updatePeticion.json()
          return resolve({
            resultado: update.resultado,
            msj: update.message
          })
        }catch(error){
          return reject({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo update_estado_concepto_proveedor: ' + error.message
          })
        }
      })
    },
    async get_oracle_conceptos({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let conceptosPeticion = await fetch(`${config.BASE_URL}/bejermanAdmin/getConceptosEmpresa?empresa_codigo=${datos.empresa_codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
          })
          let conceptos = await conceptosPeticion.json()
          return resolve({
            resultado: conceptos.resultado,
            msj: conceptos.message,
            conceptosOracle: conceptos.conceptos
          })
        }catch(error){
          return reject({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo get_oracle_conceptos: ' + error.message,
            conceptosOracle: null
          })
        }
      })
    },
    async get_oracle_prov_empresa_concep_relation({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let conceptosPeticion = await fetch(`${config.BASE_URL}/bejermanAdmin/getEmpProvConcepRelation?empresa_codigo=${datos.empresa_codigo}&proveedor_codigo=${datos.proveedor_codigo}&concepto_codigo=${datos.concepto_codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
          })
          let conceptos = await conceptosPeticion.json()
          return resolve({
            resultado: conceptos.resultado,
            msj: conceptos.message,
            relationOracle: conceptos.listadoRelacion
          })
        }catch(error){
          return reject({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo get_oracle_prov_empresa_concep_relation: ' + error.message,
            relationOracle: null
          })
        }
      })
    },
    async save_new_relation_CxP({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let savePetition = await fetch(`${config.BASE_URL}/bejermanAdmin/saveNewRelationCxP`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({
              empresa_codigo: datos.empresa_codigo,
              detalles: datos.detalles
            })
          })
          let save = await savePetition.json()
          return resolve({
            resultado: save.resultado,
            msj: save.message
          })
        }catch(error){
          return reject({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo save_new_relation_CxP: ' + error.message
          })
        }
      })
    },
    async get_fc_data({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let estadosPeticion = await fetch(`${config.BASE_URL}/bejermanAdmin/getFcData`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let estados = await estadosPeticion.json()
          return resolve({
            resultado: estados.resultado,
            msj: estados.message,
            estados: estados.estados,
            proveedores: estados.proveedores,
            estados_control: estados.estados_control,
            permiso: estados.permiso,
            permiso_pagar: estados.permisoPagar
          })
        }catch(error){
          return reject({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo get_estados_fc: ' + error.message,
            estados: null,
            proveedores: null
          })
        }
      })
    },
    async get_facturas_compra_admin({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let facturasPeticion = await fetch(`${config.BASE_URL}/bejermanAdmin/getFacturasCompraAdmin`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let facturas = await facturasPeticion.json()
          return resolve({
            resultado: facturas.resultado,
            msj: facturas.message,
            facturasComp: facturas.facturasCompra
          })
        }catch(error){
          return reject({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo get_facturas_compra_admin: ' + error.message,
            facturasComp: null
          })
        }
      })
    },
    async get_tipos_pedido ({ state, commit }) {
      try {
        if (state.tipos_pedido.length == 0) {
          const res = await fetch(`${config.BASE_URL}/bejermanAdmin/tiposPedidos`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_tipos_pedido', data.data)
          } else {
            commit('set_tipos_pedido', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_tipos_pedido', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_emisiones ({ state, commit }) {
      try {
        if (state.emisiones.length == 0) {
          const res = await fetch(`${config.BASE_URL}/bejermanAdmin/emisiones`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_emisiones', data.data)
          } else {
            commit('set_emisiones', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_emisiones', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_locales ({ state, commit }) {
      try {
        if (state.locales.length == 0) {
          const res = await fetch(`${config.BASE_URL}/bejermanAdmin/localesBejerman`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_locales', data.data)
          } else {
            commit('set_locales', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_locales', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async generar_archivos ({}, filtro) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/bejermanAdmin/generarArchivos`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(filtro)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    }
  }
}