import config from '../../config'
import store from '../store'
import { order_list_by } from '../util/utils'

export default {
  namespaced: true,
  state: {
    articulos_activos: [],
  },
  mutations: {
    set_articulos_activos (state, payload) {
      state.articulos_activos = payload
      order_list_by(state.articulos_activos, 'nombre')
    }
  },
  actions: {
    async get_articulo_activo ({}, codigo) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/articulos/articuloActivo?codigo=${codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_arts_activos({}, datos) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/articulos/artsActivos`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            resolve(data)
          }

        } catch (error) {
          resolve(error)
        }
      })
    },
    async get_articulos_activos ({ commit }) {
      try {
        const res = await fetch(`${config.BASE_URL}/articulos/articulosActivos`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': store.state.long_token
          }
        })
    
        const data = await res.json()
    
        if (data.exito == 1) {
          commit('set_articulos_activos', data.data)
        } else {
          commit('set_articulos_activos', [])
          store.dispatch('show_snackbar', {
            text: data.message,
            color: 'error'
          })
        }
    
      } catch (error) {
        commit('set_articulos_activos', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    /*
          RODRIGO
    */
    async initABMArticulos({commit}){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/articulos/initABMArticulos`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();
          if(data.exito === 1){
            resolve(data);
          }else{
            reject(data);
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    //Para buscar general
    async getArticulos({}, bus){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/articulos/getArticulos?articulo_codigo=${bus.articuloCodigo}&articulo_marca=${bus.marca}&articulo_modelo=${bus.modelo}&articulo_rubro=${bus.rubro}&articulo_categoria=${bus.categoria}&articulo_estado=${bus.estado}&tipo=${bus.tipo}&nuevo=1`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    //es para un solo articulo
    async getArticulo({},id){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/articulos/getArticulos?articulo_codigo=${id}&articulo_marca=&articulo_modelo=&articulo_rubro=&articulo_categoria=&articulo_estado=&tipo=&nuevo=0`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async getPreciosCompra({},id){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/articulos/getPreciosCompra?articulo_codigo=${id}`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async getCodigosBarraArt({}, id){
      return await new Promise(async (resolve, reject) =>{
        try {
          const res = await fetch(`${config.BASE_URL}/articulos/getCodigosBarraArt?articulo_codigo=${id}`,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json();
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async nuevoArticulo({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/articulos/nuevoArticulo`,{
            method: 'POST',
            headers:{
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
          const data = await res.json();
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    async actualizarArticulo({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/articulos/actualizarArticulo`,{
            method: 'POST',
            headers:{
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
          const data = await res.json();
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    async actualizarCodigosBarra({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/articulos/saveCodigosBarra`,{
            method: 'POST',
            headers:{
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          });
          const data = await res.json();
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error);
        }
      })
    },
    async get_articulo ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/articulos/articulo?local=${payload.local}&codigo=${payload.codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async get_series ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/articulos/series?articulo=${payload.articulo}&local=${payload.local}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    }
  }
}
    