import config from '../../config'
import store from '../store'

export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    async initRecepIndex({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let initPeticion = await fetch(`${config.BASE_URL}/adminRecepciones/initRecepIndex`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let init = await initPeticion.json()
          init.msj = init.message
          return resolve(init)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo initRecepIndex: ' + error.message
          })
        }
      })
    },
    async getRecepciones({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let recepPeticion = await fetch(`${config.BASE_URL}/adminRecepciones/getRecepciones?deposito_codigo=${datos.deposito_codigo}&proveedor_codigo=${datos.proveedor_codigo}&estado_codigo=${datos.estado_codigo}&numero=${datos.numero}&fecha_desde=${datos.fecha_desde}&fecha_hasta=${datos.fecha_hasta}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let recep = await recepPeticion.json()
          recep.msj = recep.message
          return resolve(recep)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getRecepciones: ' + error.message
          })
        }
      })
    },
    async initUnaRecepForm({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let initPeticion = await fetch(`${config.BASE_URL}/adminRecepciones/initUnaRecepForm?nuevo=${datos.nuevo}&recepcion_id=${datos.recepcion_id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let init = await initPeticion.json()
          init.msj = init.message
          return resolve(init)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo initUnaRecepForm: ' + error.message
          })
        }
      })
    },
    async getOrdenesCompra({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let ordenesPeticion = await fetch(`${config.BASE_URL}/adminRecepciones/getOrdenesCompra?deposito_codigo=${datos.deposito_codigo}&proveedor_codigo=${datos.proveedor_codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let ordenes = await ordenesPeticion.json()
          ordenes.msj = ordenes.message
          return resolve(ordenes)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getOrdenesCompra: ' + error.message
          })
        }
      })
    },
    async getDetallesOrdenCompra({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let detsPeticion = await fetch(`${config.BASE_URL}/adminRecepciones/getDetallesOrdenCompra?deposito_codigo=${datos.deposito_codigo}&orden_id=${datos.orden_id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let dets = await detsPeticion.json()
          dets.msj = dets.message
          return resolve(dets)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getDetallesOrdenCompra: ' + error.message
          })
        }
      })
    },
    async getArticulosProveedor({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let artsPeticion = await fetch(`${config.BASE_URL}/adminRecepciones/getArticulosProveedor?proveedor_codigo=${datos.proveedor_codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let arts = await artsPeticion.json()
          arts.msj = arts.message
          return resolve(arts)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getArticulosProveedor: ' + error.message
          })
        }
      })
    },
    async createRecepcion({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let crearPeticion = await fetch(`${config.BASE_URL}/adminRecepciones/createRecepcion`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let crear = await crearPeticion.json()
          crear.msj = crear.message
          return resolve(crear)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo createRecepcion: ' + error.message
          })
        }
      })
    },
    async anularRecepcion({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let anularPeticion = await fetch(`${config.BASE_URL}/adminRecepciones/anularRecepcion`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let anular = await anularPeticion.json()
          anular.msj = anular.message
          return resolve(anular)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo anularRecepcion: ' + error.message
          })
        }
      })
    },
    async asociarArtCodBarras({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let asociarPeticion = await fetch(`${config.BASE_URL}/adminRecepciones/asociarArtCodBarras`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let asociar = await asociarPeticion.json()
          asociar.msj = asociar.message
          return resolve(asociar)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo asociarArtCodBarras: ' + error.message
          })
        }
      })
    },
    async recepcionVSorden({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let crucePeticion = await fetch(`${config.BASE_URL}/adminRecepciones/recepcionVSorden`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let cruce = await crucePeticion.json()
          cruce.msj = cruce.message
          return resolve(cruce)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo recepcionVSorden: ' + error.message
          })
        }
      })
    },
    async getExcelDetsOrdComp({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let excelPeticion = await fetch(`${config.BASE_URL}/adminRecepciones/getExcelDetsOrdComp?orden_id=${datos.orden_id}&deposito_codigo=${datos.deposito_codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let excel = await excelPeticion.json()
          excel.msj = excel.message
          return resolve(excel)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getExcelDetsOrdComp: ' + error.message
          })
        }
      })
    }
  }
}