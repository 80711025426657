import config from '../../../config'
import store from '../../store'
export default{
  namespaced: true,
  state:{

  },
  mutations:{

  },
  actions:{
    //conciliaTarjetas/initConciliacion
    async initConciliacionManual({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/conciliaTarjetas/initConciliacion?ver=${payload}`,{
            method: 'GET',
            headers:{
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      });
    },
    async searchLiquidaciones({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/conciliaTarjetas/searchLiquidaciones?forma_cobro_codigo=${payload.filtro.formasDeCobro}&tarjeta_codigo=${payload.filtro.tarjeta}&forma_pago_tar_id=${payload.filtro.formaPagoTarjeta}&liquidacion_id=${payload.filtro.liqId}&nro_resumen=${payload.filtro.nroResumen}&nro_comercio=${payload.filtro.nroComercio}&fecha_desde=${payload.filtro.fechaDesde}&fecha_hasta=${payload.filtro.fechaHasta}&mov_bancario=${payload.filtro.movBancario}&ver=${payload.ver}`,{
            method: 'GET',
            headers:{
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      });
    },
    async generarUnMovBancario({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/conciliaTarjetas/generateOneMovBancario`,{
            method: 'POST',
            headers:{
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
          const data = await res.json();
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    async anularMovsBancarios({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/conciliaTarjetas/anularMovsBancarios`,{
            method: 'POST',
            headers:{
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
          const data = await res.json();
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    async borrarLiquidacion({},payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/conciliaTarjetas/deleteLiquidacion`,{
            method: 'POST',
            headers:{
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
          const data = await res.json();
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    async conciliarTodo({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/conciliaTarjetas/conciliarTodo`,{
            method: 'POST',
            headers:{
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
          const data = await res.json();
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    async getMovMultiples({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/conciliaTarjetas/getMovsMultiples?liquidacion_id=${payload}`,{
            method: 'GET',
            headers:{
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      });
    },
    async enviarMovMultiples({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/conciliaTarjetas/sendMovsMultiples`,{
            method: 'POST',
            headers:{
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
          const data = await res.json();
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    async getCuentasBanco({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/conciliaTarjetas/getCuentasBanco?banco_codigo=${payload}`,{
            method: 'GET',
            headers:{
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      });
    },
    async obtenerDatosDetalleLiq({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/conciliaTarjetas/getDatosDetalleLiq?detalle_id=${payload.detalle_id}&tipo_pedido=${payload.tipo_pedido}&numero_pedido=${payload.numero_pedido}`,{
            method: 'GET',
            headers:{
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      });
    },
    async desconciliarLiquidacion({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/conciliaTarjetas/desconciliarUnaOp`,{
            method: 'POST',
            headers:{
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
          const data = await res.json();
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    async conciliarOkDetalleLiq({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/conciliaTarjetas/conciliarOkUnaOp`,{
            method: 'POST',
            headers:{
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
          const data = await res.json();
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    //conciliarConObsUnaOp
    async conciliarConObsDetLiq({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/conciliaTarjetas/conciliarConObsUnaOp`,{
            method: 'POST',
            headers:{
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
          const data = await res.json();
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    async conciliarManualDetLiquidacion({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/conciliaTarjetas/conciliarManualUnaOp`,{
            method: 'POST',
            headers:{
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
          const data = await res.json();
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    async getPedidosEstimados({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/conciliaTarjetas/getPedidosEstimados?nro_pedido=${payload.numPedido}&tipo_pedido=${payload.tipoPedido}&forma_pago=${payload.formaPago}&codigo_autorizacion=${payload.codAutor}&nro_tarjeta=${payload.nroTar}&marca=${payload.marcaNro}&marca_nombre=${payload.marcaNom}&lote=${payload.lote}&cupon=${payload.cupon}&caja_codigo=${payload.cjaNro}&fecha_desde=${payload.fechaDesde}&fecha_hasta=${payload.fechaHasta}&importe=${payload.importe}&id=${payload.id}`,{
            method: 'GET',
            headers:{
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      });
    },
    async enviarDescuentosLiquidacion({} , payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/conciliaTarjetas/setDescuentosLiq`,{
            method: 'POST',
            headers:{
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
          const data = await res.json();
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    async actualizarEncabezadoLiq({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/conciliaTarjetas/sendEncabezadoLiq`,{
            method: 'POST',
            headers:{
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
          const data = await res.json();
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    // /saveObservacion, tipo POST, parámteros: detalle_id (obligatorio, numerico, id de la row det), observacion(no null, obligatorio)
    async guardarObservacionDetalleLiq({}, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/conciliaTarjetas/saveObservacion`,{
            method: 'POST',
            headers:{
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
          const data = await res.json();
          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      })
    },
  },
  getters:{

  }
}