import config from '../../../config'
import store from '../../store'
import { order_list_by } from '../../util/utils'

export default {
  namespaced: true,
  state: {
    estados_mov: [],
    origenes: [],
  },
  mutations: {
    set_estados (state, payload) {
      order_list_by(payload, 'nombre')
      state.estados_mov = payload
    },
    set_origenes (state, payload) {
      order_list_by(payload, 'nombre')
      state.origenes = payload
    }
  },
  actions: {
    async get_cheque ({}, num_mov) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/movBancarios/chequeCodigo?num_mov=${num_mov}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async permiso_auditar () {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/movBancarios/permisoAudit`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()

          if (data.exito != 1) {
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
          resolve(data.permiso)
    
        } catch (error) {
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
          resolve(0)
        }
      })
    },
    async permiso_editar () {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/movBancarios/permisoEdit`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()

          if (data.exito != 1) {
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
          resolve(data.permiso)
    
        } catch (error) {
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
          resolve(0)
        }
      })
    },
    async permiso_anular () {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/movBancarios/permisoAnul`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()

          if (data.exito != 1) {
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
          resolve(data.permiso)
    
        } catch (error) {
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
          resolve(0)
        }
      })
    },
    async permiso_extracto({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let permisoPeticion = await fetch(`${config.BASE_URL}/movBancarios/permisoExtracto`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let permiso = await permisoPeticion.json()
          permiso.msj = permiso.message
          return resolve(permiso)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo permiso_extracto: ' + error.message
          })
        }
      })
    },
    async auditar ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/movBancarios/auditar`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    /*async guardar_comentario ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/movBancarios/guardarObservacion`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },*/
    async anular ({}, num_mov) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/movBancarios/anular`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ num_mov: num_mov })
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async modificar ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/movBancarios/modificar`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_caja ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/movBancarios/getCaja`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_imputaciones ({}, num_mov) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/movBancarios/imputaciones?num_mov=${num_mov}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_movimientos ({}, filtro) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/movBancarios/getMovimientos`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(filtro)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_gestores ({}, filtro) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/movBancarios/getGestores`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(filtro)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_origenes ({ state, commit }) {
      if (state.origenes.length == 0) {
        try {
          const res = await fetch(`${config.BASE_URL}/movBancarios/origenes`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            commit('set_origenes', data.data)
          } else {
            commit('set_origenes', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
    
        } catch (error) {
          commit('set_origenes', [])
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        }
      }
    },
    async get_estados ({ state, commit }) {
      if (state.estados_mov.length == 0) {
        try {
          const res = await fetch(`${config.BASE_URL}/movBancarios/estados`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          if (data.exito == 1) {
            commit('set_estados', data.data)
          } else {
            commit('set_estados', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
    
        } catch (error) {
          commit('set_estados', [])
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        }
      }
    }
  }
}