import config from '../../config'
import store from '../store'

export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    /*
      TAMAÑOS (Existe pero no nadie lo ve, ni lo usa)
    */
    async nuevoEditarTam ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/nuevo_editar_tam`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json();

          resolve(data);
        } catch (error) {
          let accion = payload.nuevo ? 'crear' : 'editar';
          resolve({
            resultado: 0,
            message: `Ocurrió un error al intentar ${accion} el tamaño: `+error.message
          });
        }
      })
    },
    async get_tamanios ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/get_tamanios?nombre=${payload.nombre}&inhabilitado=${payload.inhabilitado}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
          })

          const data = await res.json();

          resolve(data);
        } catch (error) {
          resolve({
            message:  'Ocurrió un problema al obtener los tamaños: '+error.message,
            resultado: 0      
          });
        }
      })
    },
    /*
      GENERALES
    */
    async getPermiso ({commit}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/getPermisoFuncion?funcion_codigo=${parseInt(payload.funcion_codigo)}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json();
          resolve(data)
  
        } catch (error) {
          resolve({
            exito: 0,
            msj: 'Ocurrió un error al obtener permiso: '+error
          })
        }
      })
    },
    async getTieneRol ({commit}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/getTieneRol?rol=${payload.rol}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json();
          resolve(data)
  
        } catch (error) {
          resolve({
            exito: 0,
            msj: 'Ocurrió un error al obtener el rol: '+error
          })
        }
      })
    },
    /*
      SERIES
    */
    async getInfoSerieCambioDirecto ({commit}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/acc/series/getInfoSerieCambioDirecto?serie=${payload.serie}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json();
          resolve(data)
  
        } catch (error) {
          resolve({
            exito: 0,
            message: 'Ocurrió un error al obtener la información de la serie: '+error
          })
        }
      })
    },
    /* 
      ABM BANNERS
    */
   //trae todas las empresas habilitadas
    async getEmpresas({}, payload) {
      return await new Promise(async (resolve,reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/getEmpresas`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
          })

          const data = await res.json();

          resolve(data);
        } catch (error) {
          resolve({
            message:  'Ocurrió un problema al obtener las empresas habilitadas (getEmpresas): '+error.message,
            resultado: 0      
          });
        }
      })
    },
    //es el Buscar del index
    async getBannersForm({}, payload){
      return await new Promise(async (resolve,reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/getBannersForm?empresa_codigo=${payload.empresa_codigo}&inhabilitado=${payload.inhabilitado}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
          })

          const data = await res.json();

          resolve(data);
        } catch (error) {
          resolve({
            message:  'Ocurrió un problema al Obtener los banners (getBannersForm): '+error.message,
            resultado: 0      
          });
        }
      })
    },
    //trae las empreas relacionadas a ese banner y otra info
    async initBanner({}, payload){
      return await new Promise(async (resolve,reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/initBanner?id=${payload.id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
          })

          const data = await res.json();

          resolve(data);
        } catch (error) {
          resolve({
            message:  'Ocurrió un problema al obtener información del banner (initBanner): '+error.message,
            resultado: 0      
          });
        }
      })
    },
    async nuevoBanners({}, payload){
      return await new Promise(async (resolve,reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/nuevoBanners`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json();

          resolve(data);
        } catch (error) {
          resolve({
            message:  'Ocurrió un problema al crear el banner: '+error.message,
            resultado: 0      
          });
        }
      })
    },
    async inhabilitarHabilitarBanner({}, payload) {
      return await new Promise(async (resolve,reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/inhabilitarHabilitarBanner`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json();

          resolve(data);
        } catch (error) {
          let cad = payload.accion == 1 ? 'inhabilitar' : 'habilitar'; 
          resolve({
            message:  'Ocurrió un problema al '+cad+' el banner: '+error.message,
            resultado: 0      
          });
        }
      })
    },
    async editarBanners({}, payload) {
      return await new Promise(async (resolve,reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/editarBanners`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json();

          resolve(data);
        } catch (error) {
          resolve({
            message:  'Ocurrió un problema al editar el banner: '+error.message,
            resultado: 0      
          });
        }
      })
    },
  }
}