<template>
  <v-dialog
    v-model="dialog"
    max-width="1000"
    :fullscreen="fullscreen"
    scrollable
  >
    <v-card>
      <v-card-title>
        {{ titulo }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          class="mr-2"
          :title="fullscreen ? 'Minimizar' : 'Maximizar'"
          @click="fullscreen = !fullscreen"
        >
          <v-icon>
            fas fa-window-{{ fullscreen ? 'restore' : 'maximize' }}
          </v-icon>
        </v-btn>
        <v-btn
          icon
          title="Cerrar"
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text
        class="pa-0"
        :style="$vuetify.breakpoint.smAndDown ? 'height: 500px' : 'height: 1000px'"
      >
        <vue-pdf-app
          :pdf="pdf"
          :file-name="nombre"
          :config="{
            toolbar: {
              toolbarViewerRight: {
                presentationMode: false,
                openFile: false,
                print: print,
                download: true,
                viewBookmark: false
              }
            }
          }"
        ></vue-pdf-app>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import VuePdfApp from "vue-pdf-app"
// import this to use default icons for buttons
import "vue-pdf-app/dist/icons/main.css"

export default {
  data () {
    return {
      fullscreen: false
    }
  },
  props: {
    value: Boolean,
    pdf: {
      type: [ ArrayBuffer, String ],
    },
    nombre: String,
    titulo: String,
    print: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VuePdfApp
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    dialog (val) {
      val || (this.fullscreen = false)
    }
  }
}
</script>