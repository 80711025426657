import config from '../../../config'
import store from '../../store'
import { order_list_by } from '../../util/utils'

export default {
  namespaced: true,
  state: {
    tipos_archivo: [],
    estados_archivo: []
  },
  mutations: {
    set_tipos (state, payload) {
      order_list_by(payload, 'nombre')
      state.tipos_archivo = payload
    },
    set_estados (state, payload) {
      order_list_by(payload, 'nombre')
      state.estados_archivo = payload
    }
  },
  actions: {
    async cambiar_estado ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/archivos/cambiarEstado`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async eliminar_archivo ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/archivos/eliminar`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ id: id })
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async subir_archivo ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/archivos/subir`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_archivos ({}, filtro) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/archivos/search?tipo=${filtro.tipo ? filtro.tipo : ''}&estado=${filtro.estado ? filtro.estado : ''}&desde=${filtro.desde ? filtro.desde : ''}&hasta=${filtro.hasta ? filtro.hasta : ''}&id=${filtro.id ? filtro.id : ''}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_estados ({ state, commit }) {
      try {
        if (state.estados_archivo.length == 0) {
          const res = await fetch(`${config.BASE_URL}/archivos/estados`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_estados', data.data)
          } else {
            commit('set_estados', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_estados', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_tipos ({ state, commit }) {
      try {
        if (state.tipos_archivo.length == 0) {
          const res = await fetch(`${config.BASE_URL}/archivos/tipos`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_tipos', data.data)
          } else {
            commit('set_tipos', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_tipos', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    }
  }
}