import config from '../../../config'
import store from '../../store'
import { order_list_by } from '../../util/utils'

export default {
  namespaced: true,
  state: {
    bodegas_user: [] // depositos habilitados del usuario por empresa y sucursal
  },
  mutations: {
    set_bodegas_user (state, payload) {
      state.bodegas_user = payload
      order_list_by(state.bodegas_user, 'nombre')
      localStorage.setItem('bodegas_user', JSON.stringify(payload))
    }
  },
  actions: {
    async inhabilitar ({}, codigo) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/bodegas/inhabilitar`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ codigo: codigo })
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async habilitar ({}, codigo) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/bodegas/habilitar`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ codigo: codigo })
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async editar ({}, bodega) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/bodegas/editar`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(bodega)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async nueva ({}, bodega) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/bodegas/nueva`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(bodega)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_locales ({}, filtro) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/bodegas/getLocales`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(filtro)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_tipos () {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/bodegas/tipos`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_bodegas ({}, filtro) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/bodegas/getBodegas`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(filtro)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    // obtiene las bodegas habilitadas y las guarda en el localStorage para utilizarlas en constrolStock
    async get_bodegas_user ({ dispatch, commit, state }) {
      if (state.bodegas_user.length == 0) {
        await dispatch('get_bodegas', { inhabilitada: 0 })
          .then((res) => {
            commit('set_bodegas_user', res.data)
          })
          .catch((error) => {
            commit('set_bodegas_user', [])
            store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
      }
    }
  }
}