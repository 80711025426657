import config from '../../config'
import store from '../store'

export default {
  namespaced: true,
  state: {
    tipos_deposito: []
  },
  mutations: {
    set_tipos_deposito (state, payload) {
      state.tipos_deposito = payload
    }
  },
  actions: {
    async get_tipos_deposito ({ state, commit }) {
      try {
        if (state.tipos_deposito.length == 0) {
          const res = await fetch(`${config.BASE_URL}/dashboard/tiposDeposito`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_tipos_deposito', data.data)
          } else {
            commit('set_tset_tipos_depositoipos', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
        }
      } catch (error) {
        commit('set_tipos_deposito', [])
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
    },
    async get_indices_quiebre ({}, filtro) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/dashboard/indiceQuiebre?rubros=${filtro.rubros}&categorias=${filtro.categorias}&tipos_dep=${filtro.tipos_dep}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_indices_rotacion ({}, filtro) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/dashboard/indiceRotacion?rubros=${filtro.rubros}&categorias=${filtro.categorias}&tipos_dep=${filtro.tipos_dep}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_indices_inmovilizacion ({}, filtro) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/dashboard/indiceInmovilizacion?rubros=${filtro.rubros}&categorias=${filtro.categorias}&tipos_dep=${filtro.tipos_dep}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_indices_sobrestock ({}, filtro) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/dashboard/indiceSobrestock?rubros=${filtro.rubros}&categorias=${filtro.categorias}&tipos_dep=${filtro.tipos_dep}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_indices_obsoletos ({}, filtro) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/dashboard/indiceObsoletos?rubros=${filtro.rubros}&categorias=${filtro.categorias}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_indices_defectuosos ({}, filtro) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/dashboard/indiceDefectuosos?rubros=${filtro.rubros}&categorias=${filtro.categorias}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_indices_cobertura ({}, filtro) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/dashboard/indiceCobertura?rubros=${filtro.rubros}&categorias=${filtro.categorias}&tipos_dep=${filtro.tipos_dep}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    }
  }
}