import config from '../../config'
import store from '../store'
import { order_list_by } from '../util/utils'

export default {
  namespaced: true,
  state: {
    formas_cobro: [],
    formas_cobro_cod: []
  },
  mutations: {
    set_formas_cobro (state, payload) {
      state.formas_cobro = payload
      order_list_by(state.formas_cobro, 'nombre')
      localStorage.setItem('formas_cobro', JSON.stringify(payload))
    },
    set_formas_cobro_cod (state, payload) {
      state.formas_cobro_cod = payload
      order_list_by(state.formas_cobro_cod, 'nombre')
    }
  },
  actions: {
    async get_formas_cobro () {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/cobros/formasCobro`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    // obtiene las formas de cobro habilitadas y las guarda en el localStorage para utilizarlas en otros formularios
    async get_formas_cobro_habilitadas ({ state, commit, dispatch }) {
      if (state.formas_cobro.length == 0) {
        await dispatch('get_formas_cobro', { inhabilitada: 0 })
          .then((res) => {
            commit('set_formas_cobro', res.data)
          })
          .catch(error => {
            commit('set_formas_cobro', [])
            store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
      }
    },
    async init_index_cobros({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let opPeticion = await fetch(`${config.BASE_URL}/cobros/initFormCobros?nuevo=${datos.nuevo}&id=${datos.id}&tipo_pedido=${datos.tipo_pedido}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let operaciones = await opPeticion.json()
          operaciones.msj = operaciones.message
          return resolve(operaciones)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo init_index_cobros: ' + error.message,
            operaciones: null
          })
        }
      })
    },
    async get_cobros_online({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let cobrosPeticion = await fetch(`${config.BASE_URL}/cobros/getCobrosOnline?empresa=${datos.empresa}&sucursal=${datos.sucursal}&pto_vta=${datos.pto_vta}&operacion=${datos.operacion}&estado=${datos.estado}&fecha_desde=${datos.fecha_desde}&fecha_hasta=${datos.fecha_hasta}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let cobros = await cobrosPeticion.json()
          cobros.msj = cobros.message
          return resolve(cobros)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo get_cobros_online: ' + error.message,
            cobros: null
          })
        }
      })
    },
    async anular_cobro_electronico({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let anulacionPeticion = await fetch(`${config.BASE_URL}/decidir/anular`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let anulacion = await anulacionPeticion.json()
          if (anulacion.exito == 1){
            return resolve({
              resultado: 1,
              msj: 'OK'
            })
          }else{
            return resolve({
              resultado: 0,
              msj: anulacion.mensaje
            })
          }
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo anular_cobro_electronico: ' + error.message
          })
        }
      })
    },
    async process_payment({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let cobroPeticion = await fetch(`${config.BASE_URL}/decidir/Pago`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let cobro = await cobroPeticion.json()
          return resolve({
            resultado: 1,
            msj: 'OK',
            datosCobro: cobro
          })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo process_payment: ' + error.message,
            datosCobro: null
          })
        }
      })
    },
    async payment_status ({}, datos) {
      return await new Promise(async (resolve, reject) => {
        try {
          let cobroPeticion = await fetch(`${config.BASE_URL}/decidir/unPago`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let cobro = await cobroPeticion.json()
          return resolve({
            resultado: 1,
            msj: 'OK',
            datosStaus: cobro,
            idDecidir: datos.id_decidir,
            reprocesar: datos.reprocesar,
            idSolicitud: datos.id_solicitud
          })
        } catch (error) {
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo payment_status: ' + error.message,
            datosStaus: null,
            idDecidir: null,
            reprocesar: null,
            idSolicitud: null
          })
        }
      })
    },
    async get_pdf_cobro_data({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let dataPeticion = await fetch(`${config.BASE_URL}/cobros/getPdfCobroData?id=${datos.id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let data = await dataPeticion.json()
          data.msj = data.message
          return resolve(data)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo get_pdf_cobro_data: ' + error.message,
            cobro: null
          })
        }
      })
    },
    async check_cobro_sin_asociar({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let checkPeticion = await fetch(`${config.BASE_URL}/cobros/puedoAnularCobroSinAsociar?transaccion_id=${datos.transaccion_id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let check = await checkPeticion.json()
          check.msj = check.message
          return resolve(check)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo check_cobro_sin_asociar: ' + error.message,
            cobro: null
          })
        }
      })
    },
    async get_formas_cobro_cod ({ state, commit }) {
      if (state.formas_cobro_cod.length == 0) {
        try {
          const res = await fetch(`${config.BASE_URL}/cobros/formasCobroCod`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
      
          const data = await res.json()
      
          if (data.exito == 1) {
            commit('set_formas_cobro_cod', data.data)
          } else {
            commit('set_formas_cobro_cod', [])
            store.dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
      
        } catch (error) {
          commit('set_formas_cobro_cod', [])
          store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        }
      }
    },
    async cancelar_cobro_electronico ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/cobros/cancelarCobroVta`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    }
  }
}