import config from '../../../config'
import store from '../../store'
import { format_date } from '../../util/utils'

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    async eliminar ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/creditosDebitos/eliminar`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async editar ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/creditosDebitos/editar`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async nuevo ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/creditosDebitos/nuevo`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_creditos_debitos ({}, filtro) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/creditosDebitos/getCreditosDebitos`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({
              nombre: filtro.nombre,
              cliente: filtro.cliente,
              desde: format_date(filtro.fecha[0], 'DD/MM/YYYY'),
              hasta: format_date(filtro.fecha[1], 'DD/MM/YYYY'),
              tipo: filtro.tipo
            })
          })
    
          const data = await res.json()
    
          if (data.exito === 1) {
            resolve(data)
          } else {
            reject(data)
          }
    
        } catch (error) {
          reject(error)
        }
      })
    },
  }
}