import config from '../../config'
import store from '../store'
export default {
  namespaced: true,
  state: {
    bodOrigen: [],
    bodDestino: [],
    estadosRemitos: {},
    estadosRemitosDev: {}
  },
  mutations: {
    setBodegasOrigen(state, payload){
      state.bodOrigen = payload
      localStorage.setItem('bodegasO', JSON.stringify(payload))
    },
    setBodegasDestino(state, payload){
      state.bodDestino = payload
      localStorage.setItem('bodegasD', JSON.stringify(payload))
    },
    setEstadosRemitos(state, payload){
      state.estadosRemitos = payload
      localStorage.setItem('estadosRemitos', JSON.stringify(payload))
    },
    setEstadosDevolucion(state, payload){
      state.estadosRemitosDev = payload
      localStorage.setItem('estadosRemitosDev', JSON.stringify(payload))
    }
  },
  actions: {
    async initForm({commit}){
      return await new Promise(async (resolve, reject) => {
        try{
          let initPeticion = await fetch(`${config.BASE_URL}/adminRemitosInternos/initForm`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let init = await initPeticion.json()
          init.msj = init.message
          if (init.resultado == 1){
            commit('setBodegasOrigen', init.bodegasO)
            commit('setBodegasDestino', init.bodegasD)
            commit('setEstadosRemitos', init.estados)
            // comentado por mmacoritto el 8/7/23 pq devolvia undefined y rompia el loadLocalStorage
            //commit('setEstadosDevolucion', init.estadosDev)
          }
          return resolve(init)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo initForm: ' + error.message
          })
        }
      })
    },
    async getRemitos({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let remitosPeticion = await fetch(`${config.BASE_URL}/adminRemitosInternos/getRemitosInternos?bodega_origen=${datos.bodega_origen}&bodega_destino=${datos.bodega_destino}&estado=${datos.estado}&remito_numero=${datos.remito_numero}&fecha_desde=${datos.fecha_desde}&fecha_hasta=${datos.fecha_hasta}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let remitos = await remitosPeticion.json()
          remitos.msj = remitos.message
          return resolve(remitos)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getRemitos: ' + error.message
          })
        }
      })
    },
    async getUnRemito({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let remitoPeticion = await fetch(`${config.BASE_URL}/adminRemitosInternos/getUnRemitoInterno?remito_numero=${datos.numero}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let remito = await remitoPeticion.json()
          remito.msj = remito.message
          return resolve(remito)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getUnRemito: ' + error.message
          })
        }
      })
    },
    async anularRemito({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let anularPeticion = await fetch(`${config.BASE_URL}/adminRemitosInternos/anularRemito`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let anular = await anularPeticion.json()
          anular.msj = anular.message
          return resolve(anular)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo anularRemito: ' + error.message
          })
        }
      })
    },
    async getArticulosXBodega({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let articulosPeticion = await fetch(`${config.BASE_URL}/adminRemitosInternos/getArticulosXBodega?bodega=${datos.bodega}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let articulos = await articulosPeticion.json()
          articulos.msj = articulos.message
          return resolve(articulos)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getArticulosXBodega: ' + error.message
          })
        }
      })
    },
    async checkRemitosPendientes({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let pendientesPeticion = await fetch(`${config.BASE_URL}/adminRemitosInternos/checkRemitosPendientes?bodega=${datos.bodega}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let pendientes = await pendientesPeticion.json()
          pendientes.msj = pendientes.message
          return resolve(pendientes)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo checkRemitosPendientes: ' + error.message
          })
        }
      })
    },
    async nuevoRemitoInterno({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let nuevoPeticion = await fetch(`${config.BASE_URL}/adminRemitosInternos/nuevoRemitoInterno`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let nuevoRemito = await nuevoPeticion.json()
          nuevoRemito.msj = nuevoRemito.message
          return resolve(nuevoRemito)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo nuevoRemitoInterno: ' + error.message
          })
        }
      })
    },
    async getRemitoPDF({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let pdfPeticion = await fetch(`${config.BASE_URL}/adminRemitosInternos/getRemitoPDF?remito_numero=${datos.remito_numero}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let pdf = await pdfPeticion.json()
          pdf.msj = pdf.message
          return resolve(pdf)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getRemitoPDF: ' + error.message
          })
        }
      })
    },
    async get_etiquetas ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/adminRemitosInternos/getEtiquetas?remito=${id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async aceptar_remito ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/adminRemitosInternos/aceptarRemito`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    }
  }
}