import config from "../../../config"
import store from "../../store"
import { formatQuery } from '../../util/utils'

export default {
  namespaced: true,
  state: {
    p_crear: false,
    p_fraude: false
  },
  mutations: {
    set_permiso_crear (state, payload) {
      state.p_crear = payload
    },
    set_permiso_fraude (state, payload) {
      state.p_fraude = payload
    }
  },
  actions: {
    async getClienteMayorista({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let clientePeticion = await fetch(`${config.BASE_URL}/adminClientes/getClienteMayorista?codigo=${datos.codigo}&ejecutivo_codigo=${datos.ejecutivo_codigo}&nombre=${datos.nombre}&nroDocumento=${datos.nroDocumento}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let cliente = await clientePeticion.json()
          cliente.msj = cliente.message
          return resolve(cliente)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getClienteMayorista: ' + error.message,
            cliente: null
          })
        }
      })
    },
    async initFormNewClienteM({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let initPeticion = await fetch(`${config.BASE_URL}/adminClientes/initNewClienteMayorista`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let init = await initPeticion.json()
          init.msj = init.message
          return resolve(init)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo initFormNewClienteM: ' + error.message
          })
        }
      })
    },
    async setNuevoClienteMayorista({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let nuevoPeticion = await fetch(`${config.BASE_URL}/adminClientes/saveNewClienteMayorista`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let nuevo = await nuevoPeticion.json()
          nuevo.msj = nuevo.message
          return resolve(nuevo)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo setNuevoClienteMayorista: ' + error.message
          })
        }
      })
    },
    async getSaldosAFavor({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let saldosPeticion = await fetch(`${config.BASE_URL}/adminClientes/getSaldosAFavor?cliente_codigo=${datos.cliente_codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let saldos = await saldosPeticion.json()
          saldos.msj = saldos.message
          return resolve(saldos)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getSaldosAFavor: ' + error.message
          })
        }
      })
    },
    async get_clientes ({}, filtro) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/adminClientes/search?dni=${filtro.dni}&cuit=${filtro.cuit}&nombre=${filtro.nombre}&cond_iva=${formatQuery(filtro.cond_iva)}&tipo_doc=${formatQuery(filtro.tipo_doc)}&fraude=${filtro.fraude}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async permiso_fraude ({ commit }) {
      try {
        const res = await fetch(`${config.BASE_URL}/adminClientes/permisoFraude`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': store.state.long_token
          }
        })
  
        const data = await res.json()

        commit('set_permiso_fraude', data.permiso == 1 ? true : false)
  
      } catch (error) {
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
        commit('set_permiso_fraude', false)
      }
    },
    async permiso_crear ({ commit }) {
      try {
        const res = await fetch(`${config.BASE_URL}/adminClientes/permisoCrear`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': store.state.long_token
          }
        })
  
        const data = await res.json()

        commit('set_permiso_crear', data.permiso == 1 ? true : false)
  
      } catch (error) {
        store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
        commit('set_permiso_crear', false)
      }
    },
    async nuevo_cliente ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/adminClientes/nuevoCliente`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_coincidencias ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/adminClientes/coincidencias?id=${formatQuery(payload.codigo)}&cuit=${formatQuery(payload.cuit)}&documento=${formatQuery(payload.documento)}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()
          
          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async editar_cliente ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/adminClientes/editarCliente`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async marcar_fraude ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/adminClientes/marcarFraude`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async desmarcar_fraude ({}, id) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/adminClientes/desmarcarFraude`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify({ codigo: id })
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_escaneos_dni ({}, codigo) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/adminClientes/escaneosDNI?codigo=${codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async buscar_cliente ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/adminClientes/find?nombre=${payload.nombre}&apellido=${payload.apellido}&documento=${payload.documento}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    }
  }
}