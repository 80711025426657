import config from '../../config'
import store from '../store'

export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    async initIndices({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let initPeticion = await fetch(`${config.BASE_URL}/adminIndicesLocales/initFormIndices`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let init = await initPeticion.json()
          init.msj = init.message
          return resolve(init)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo initIndices: ' + error.message
          })
        }
      })
    },
    async getLocales({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let localesPeticion = await fetch(`${config.BASE_URL}/adminIndicesLocales/getLocalesIndices?sucursal_codigo=${datos.sucursal_codigo}&canal_codigo=${datos.canal_codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let locales = await localesPeticion.json()
          locales.msj = locales.message
          return resolve(locales)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getLocales: ' + error.message
          })
        }
      })
    },
    async getIndices({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let indicesPeticion = await fetch(`${config.BASE_URL}/adminIndicesLocales/getIndices`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            },
            body: JSON.stringify(datos)
          })
          let indices = await indicesPeticion.json()
          indices.msj = indices.message
          return resolve(indices)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getIndices: ' + error.message
          })
        }
      })
    },
    async getIndicesSuc({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let indicesPeticion = await fetch(`${config.BASE_URL}/adminIndicesLocales/getIndicesSucursales?canal_codigo=${datos.canal_codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let indices = await indicesPeticion.json()
          indices.msj = indices.message
          return resolve(indices)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getIndicesSuc: ' + error.message
          })
        }
      })
    },
    async getIndicesLoc({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let indicesPeticion = await fetch(`${config.BASE_URL}/adminIndicesLocales/getIndicesLocales?sucursal_codigo=${datos.sucursal_codigo}&canal_codigo=${datos.canal_codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.long_token
            }
          })
          let indices = await indicesPeticion.json()
          indices.msj = indices.message
          return resolve(indices)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getIndicesLoc: ' + error.message
          })
        }
      })
    }
  }
}