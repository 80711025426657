import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
import config from '../../config'
import { Encrypt, Decrypt } from "../util/aes"
import { order_list_by } from '../util/utils'

// librerias
import { VueEasyJwt } from "vue-easy-jwt"

// modulos
import vendedores from '../modules/vendedores'
import giftcards from '../modules/giftcards'
import caja from '../modules/caja'
import admCajas from '../modules/auditoria/admCajas'
import objetivos from '../modules/objetivos'
import dycar from '../modules/dycar'
import marcas from '../modules/generales/marcas'
import modelos from '../modules/generales/modelos'
import rubros from '../modules/generales/rubros'
import categorias from '../modules/generales/categorias'
import lineas from '../modules/generales/lineas'
import listas from '../modules/generales/listas'
import proveedores from '../modules/generales/proveedores'
import genericosAcc from '../modules/genericosAcc'
import genericos from '../modules/genericos'
import precios from '../modules/generales/precios'
import tarjetas from '../modules/auditoria/tarjetas'
import cuotas from '../modules/auditoria/cuotas'
import financiaciones from '../modules/auditoria/financiaciones'
import cobros from '../modules/cobros'
import pagos from '../modules/pagos'
import articulos from '../modules/articulos'
import bodegas from '../modules/generales/bodegas'
import bancos from '../modules/generales/bancos'
import creditosDebitos from '../modules/auditoria/creditosDebitos'
import inventario from '../modules/auditoria/inventario'
import bejerman from '../modules/bejerman/bejerman'
import facturasCompra from '../modules/facturasCompra'
import controlStock from '../modules/auditoria/controlStock'
import controlCaja from '../modules/auditoria/controlCaja'
import movBancarios from '../modules/auditoria/movBancarios'
import penalizaciones from '../modules/penalizaciones'
import grupopy from '../modules/grupopy'
import remitos from '../modules/remitos'
import stock from '../modules/stock'
import conciliacionManual from '../modules/auditoria/conciliacionManual'
import facturasAdmin from '../modules/administracion/facturas'
import ventas from '../modules/ventas'
import remitosDevolucion from '../modules/remitosDevolucion'
import clientes from '../modules/generales/clientes'
import acreditaciones from '../modules/clientes/acreditaciones'
import gestores from '../modules/generales/gestores'
import talonarios from '../modules/generales/talonarios'
import localesStore from '../modules/generales/locales'
import provinciasStore from '../modules/provincias'
import parametros from '../modules/parametros'
import laposIntegrado from '../modules/laposIntegrado'
import ordenesCompra from '../modules/compras/ordenesCompra'
import cheques from '../modules/auditoria/cheques'
import dashboard from '../modules/dashboard'
import genericosServ from '../modules/genericosServ'
import empleados from '../modules/rrhh/empleados'
import evaluaciones from '../modules/rrhh/evaluaciones'
import promociones from '../modules/promociones'
import recepciones from '../modules/recepciones'
import incidentes from '../modules/incidentes'
import archivos from '../modules/generales/archivos'
import encuestas from '../modules/encuestas'
import cambioDirecto from '../modules/cambioDirecto'
import afip from '../modules/afip'
import conciliacionBancaria from '../modules/auditoria/conciliacionBancaria'
import contribucionFranquicia from '../modules/franquicia/contribucionFranquicia'
import mayoristaCobranzas from '../modules/mayorista/mayoristaCobranzas'
import paquetes from '../modules/mayorista/paquetes'
import repartidores from '../modules/mayorista/repartidores'
import feriados from '../modules/generales/feriados'
import indices from '../modules/indices'
import ventasMay from '../modules/mayorista/ventasMay'

const jwt = new VueEasyJwt()
Vue.use(Vuex)

/**
 *   Desde la tienda se manejan todas las peticiones y consultas a la API.
 *   Cada modulo de funcionalidades está separado en la carpeta /modules con sus respectivas mini stores
 * 
 *   Para llamar una accion desde una vista o componente se debe agregar el nombre del modulo que
 *   que corresponde antes del nombre de la accion, seguido de una /
 * 
 *   this.$store.dispatch('nombre_del_modulo/nombre_de_la accion', parametros)
 * 
 *   Para llamar a una variable del state: this.$store.state.nombre_del_modulo.nombre_del_state
 * 
 */

export default new Vuex.Store({
  state: {
    cerrando: false,
    loading: false,
    loading_dct: false,
    loading_lps: false,
    loading_ultra: false,
    loading_texto: '',
    load_user_modal: false,
    load_pvta_modal: false,
    menu: null,
    menu_nombres: '',
    menu_rapido: null,
    menu_rapido_nombres: null,
    token: '',
    img_perfil: '',
    sucursales: [],
    locales: [],
    empresas: [],
    banners: [],
    notificaciones: [], // array de notificaciones no leidas
    long_token: '',
    username: '',
    snackbar: {
      value: false,
      text: '',
      color: '',
      timeout: 3000
    },
    // variables de uso general
    canales: [],
    regiones: [],
    cond_iva: [], // de TELECOM !
    ejecutivos: [],
    provincias: [],
    provincias_tmp: [],
    all_locales: [],
    dias_semana: []
  },
  mutations: {
    set_provincias_tmp (state, payload) {
      state.provincias_tmp = payload
    },
    set_provincias (state, payload) {
      state.provincias = payload
    },
    set_ejecutivos (state, payload) {
      state.ejecutivos = payload
    },
    set_cond_iva (state, payload) {
      order_list_by(payload, 'condicion_iva_nombre')
      state.cond_iva = payload
    },
    set_all_locales (state, payload) {
      state.all_locales = payload
    },
    set_regiones (state, payload) {
      state.regiones = payload
    },
    set_canales (state, payload) {
      state.canales = payload
      localStorage.setItem('canales', JSON.stringify(payload))
    },
    set_token(state, payload) {
      state.token = payload
      state.long_token = 'Basic ' + payload
      localStorage.setItem('token', JSON.stringify(payload))
    },
    set_sucursales(state, payload) {
      state.sucursales = payload
      localStorage.setItem('sucursales', JSON.stringify(payload))
    },
    set_locales(state, payload) {
      state.locales = payload
      localStorage.setItem('locales', JSON.stringify(payload))
    },
    set_empresas(state, payload) {
      state.empresas = payload
      localStorage.setItem('empresas', JSON.stringify(payload))
    },
    set_menu(state, payload) {
      state.menu = payload
      localStorage.setItem('menu', JSON.stringify(payload))
      if (state.menu) {
        let menu_nombres = []
        state.menu.forEach(item => {
          if (item.children) {
            item.children.forEach(child => {
              menu_nombres.push({
                nombre: child.text,
                ruta: child.href,
              })
            })
          } else {
            menu_nombres.push({
              nombre: item.text,
              ruta: item.href,
            })
          }
        })
        state.menu_nombres = menu_nombres 
      }
    },
    set_menu_rapido(state, payload) {
      state.menu_rapido = payload;
      localStorage.setItem('menu_rapido', JSON.stringify(payload))
      if (state.menu_rapido) {
        let menu_rapido_nombres = []
        state.menu_rapido.forEach(item => {
          /*if (item.children) {
            item.children.forEach(child => {
              menu_nombres.push({
                nombre: child.text,
                ruta: child.href,
              })
            })
          } else {*/
          menu_rapido_nombres.push({
              nombre: item.nombre,
              ruta_web: item.ruta_web,
            })
          //}
        })
        state.menu_rapido_nombres = menu_rapido_nombres 
      }
    },
    set_username(state, payload) {
      state.username = payload
    },
    set_snackbar(state, payload) {
      state.snackbar = payload
    },
    set_dark_theme(state, payload) {
      localStorage.setItem('dark', JSON.stringify(payload))
    },
    set_banners(state, payload) {
      state.banners = payload
      localStorage.setItem('banners', JSON.stringify(payload))
    },
    set_img_perfil(state, payload) {
      state.img_perfil = payload
      localStorage.setItem('img_perfil', JSON.stringify(payload))
    },
    set_notificaciones(state, payload) {
      state.notificaciones = payload
      localStorage.setItem('notificaciones', JSON.stringify(payload))
    },
    delete_notificacion(state, payload) {
      const index = state.notificaciones.indexOf(payload)
      state.notificaciones.splice(index, 1)
      localStorage.setItem('notificaciones', JSON.stringify(state.notificaciones))
    },
    load_token(state, payload) {
      state.token = payload
      state.long_token = 'Basic ' + payload
    },
    set_dias_semana(state, payload){
      state.dias_semana = payload
      localStorage.setItem('dias_semana', JSON.stringify(payload))
    }
  },
  actions: {
    // envia todas las notificaciones push pendientes de enviar y no leidas
    async enviar_push ({ state }) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/notificaciones/push`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            }
          })
          
          resolve({
            exito: 1,
            message: 'Ok'
          })

        } catch (error) {
          reject(error)
        }
      })
    },
    /**
     *  Funcion mara mostrar un dialogo que indica el tiempo aprox de procesamiento de un archivo
     */
    async tiempo_subida ({ dispatch }, size) {
      dispatch('show_snackbar', {
        text: 'Cargando archivo, por favor espere. Tiempo estimado: ' + Math.round(size / 100000) + ' segundos',
        color: 'info',
        timeout: 2000
      })
      await new Promise(resolve => setTimeout(resolve, 200))
    },
    // ----------------------------------
    //            USUARIOS
    // ----------------------------------
    async validar_usuario ({ state }, usuario) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/user/validar?usuario=${Encrypt(usuario)}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            }
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async bloquear_usuario ({ state }, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/user/bloquear`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            },
            body: JSON.stringify({
              usuario: payload.toUpperCase(),
            })
          })

          const data = await res.json()

          if (data.exito === 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async desbloquear_usuario ({ state }, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/user/desbloquear`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            },
            body: JSON.stringify({
              usuario: payload.toUpperCase(),
            })
          })

          const data = await res.json()

          if (data.exito === 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async cambiar_clave ({ state }, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/user/${payload.route}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            },
            body: JSON.stringify({
              usuario: Encrypt(payload.user.toUpperCase()),
              clave: Encrypt(payload.password),
            })
          })

          const data = await res.json()

          if (data.exito === 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async verificar_permiso ({ state }, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/user/validarPermiso`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            },
            body: JSON.stringify({
              usuario: payload.toUpperCase(),
            })
          })

          const data = await res.json()

          if (data.exito === 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_user_estado ({ state }, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/user/estado`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            },
            body: JSON.stringify({
              usuario: payload.toUpperCase(),
            })
          })

          const data = await res.json()

          if (data.exito === 1) {
            resolve(data.estado)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_dark_theme ({ commit, state }) {
      try {
        const res = await fetch(`${config.BASE_URL}/user/getDark`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': state.long_token
          }
        })
        const data = await res.json()

        if (data.exito === 1) {
          commit('set_dark_theme', data.dark)
        }

      } catch (error) {
        console.error(error)
      }
    },
    async set_theme ({ commit, state }, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/user/setDark`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            },
            body: JSON.stringify({ dark: payload })
          })

          const data = await res.json()

          if (data.exito === 1) {
            commit('set_dark_theme', payload)
            resolve(data)
          } else {
            reject(data)
          }
        } catch (error) {
          reject(error)
        }
      })
    },
    async menu({ commit, state }) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/user/menu`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            }
          })

          const data = await res.json()

          if (data.exito === 1) {
            commit('set_menu', data.result)
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async menu_rapido({ commit, state }) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/user/menu_rapido`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            }
          })

          const data = await res.json()
          if (data.resultado === 1) {
            commit('set_menu_rapido', data.menu_rapido);
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async sincronizar ({ dispatch, commit }) {
      return await new Promise(async (resolve, reject) => {
        await dispatch('menu')
          .then(async () => {
            await dispatch('get_sucursales')
            await dispatch('get_locales')
            await dispatch('get_empresas')
            await dispatch('get_banners')
            await dispatch('get_notificaciones_noleidas')
            await dispatch('menu_rapido');

            // limpia las variables que no se traen en el login
            commit('set_canales', [])
            commit('marcas/set_marcas', [])
            commit('rubros/set_rubros', [])
            commit('categorias/set_categorias', [])
            commit('pagos/set_formas_pago', [])
            commit('cobros/set_formas_cobro', [])
            commit('tarjetas/set_tarjetas', [])
            commit('controlStock/set_tp_control_stk', [])
            commit('controlStock/set_est_control_stk', [])
            commit('controlStock/set_estados_detalle', [])
            commit('controlStock/set_tp_recupero', [])
            commit('genericosAcc/set_dias_semana', [])
            commit('bodegas/set_bodegas_user', [])
            commit('remitos/setBodegasOrigen', [])
            commit('remitos/setBodegasDestino', [])
            commit('remitos/setEstadosRemitos', [])
            commit('remitos/setEstadosDevolucion', [])
            commit('caja/set_cajas_user', [])
            commit('caja/set_estados_caja', [])
            commit('stock/setStockCateg', [])
            commit('stock/setStockDepositos', [])
            commit('stock/setStockEntidades', [])
            commit('stock/setStockPeriodos', [])
            commit('stock/setStockRegiones', [])
            commit('stock/setStockRubros', [])
            localStorage.removeItem('areas_sop')
            resolve({
              message: 'Sincronización de perfil exitosa'
            })
          })
          .catch ((error) => {
            reject(error)
          })
      })
    },
    async login ({ commit, dispatch }, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/user/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              usuario: Encrypt(payload.user.toUpperCase()),
              clave: Encrypt(payload.password),
              subscripcion: localStorage.getItem('subscription')
            })
          })

          const data = await res.json()

          if (data.exito === 1) {
            commit('set_token', data.token)
            commit('set_username', Decrypt(jwt.decodeToken(data.token).credenciales.usuario))
            await dispatch('get_sucursales')
            await dispatch('get_locales')
            await dispatch('get_empresas')
            await dispatch('get_dark_theme')
            await dispatch('get_banners')
            await dispatch('get_img_perfil')
            await dispatch('get_notificaciones_noleidas')
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async logout({ commit, state }, redirect) {
      state.cerrando = true
      commit('set_username', '')
      commit('set_token', '')
      commit('set_menu', null)
      commit('set_menu_rapido', null)
      commit('set_sucursales', [])
      commit('set_locales', [])
      commit('set_empresas', [])
      commit('set_dark_theme', false)
      commit('set_banners', [])
      commit('set_img_perfil', '')
      commit('set_notificaciones', [])
      commit('set_canales', [])
      // variables de otros modulos
      commit('marcas/set_marcas', [])
      commit('rubros/set_rubros', [])
      commit('categorias/set_categorias', [])
      commit('pagos/set_formas_pago', [])
      commit('cobros/set_formas_cobro', [])
      commit('tarjetas/set_tarjetas', [])
      commit('controlStock/set_tp_control_stk', [])
      commit('controlStock/set_est_control_stk', [])
      commit('controlStock/set_estados_detalle', [])
      commit('controlStock/set_tp_recupero', [])
      commit('genericosAcc/set_dias_semana', [])
      commit('bodegas/set_bodegas_user', [])
      commit('remitos/setBodegasOrigen', [])
      commit('remitos/setBodegasDestino', [])
      commit('remitos/setEstadosRemitos', [])
      commit('remitos/setEstadosDevolucion', [])
      commit('caja/set_cajas_user', [])
      commit('caja/set_estados_caja', [])
      commit('stock/setStockCateg', [])
      commit('stock/setStockDepositos', [])
      commit('stock/setStockEntidades', [])
      commit('stock/setStockPeriodos', [])
      commit('stock/setStockRegiones', [])
      commit('stock/setStockRubros', [])
      commit('set_dias_semana', [])
      localStorage.removeItem('areas_sop')
      localStorage.removeItem('terminal_def')
      if (redirect) return location.reload()
      state.cerrando = false
    },

    // ----------------------------------
    //           GENERICOS
    // ----------------------------------
    async get_localidades_tmp ({ state }, provincia) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/localidadesTMP?provincia=${provincia}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
          
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_provincias_tmp ({ commit, dispatch, state }) {
      if (state.provincias_tmp.length == 0) {
        try {
          const res = await fetch(`${config.BASE_URL}/provinciasTMP`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_provincias_tmp', data.data)
          } else {
            commit('set_provincias_tmp', [])
            dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
  
        } catch (error) {
          commit('set_provincias_tmp', [])
          dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        }
      }
    },
    async get_base64 ({ state }, ruta) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/file64?path=${ruta}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }
          
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_info_areas_soporte ({ state }) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/areasSoporte`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_provincias ({ commit, dispatch, state }) {
      if (state.provincias.length == 0) {
        try {
          const res = await fetch(`${config.BASE_URL}/provincias`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_provincias', data.data)
          } else {
            commit('set_provincias', [])
            dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
  
        } catch (error) {
          commit('set_provincias', [])
          dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        }
      }
    },
    async get_ejecutivos ({ commit, dispatch, state }) {
      if (state.ejecutivos.length == 0) {
        try {
          const res = await fetch(`${config.BASE_URL}/ejecutivos`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_ejecutivos', data.data)
          } else {
            commit('set_ejecutivos', [])
            dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
  
        } catch (error) {
          commit('set_ejecutivos', [])
          dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        }
      }
    },
    async get_cond_iva ({ commit, dispatch, state }) { // DE TELECOM !!
      if (state.cond_iva.length == 0) {
        try {
          const res = await fetch(`${config.BASE_URL}/condicionesIVA`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_cond_iva', data.data)
          } else {
            commit('set_cond_iva', [])
            dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
  
        } catch (error) {
          commit('set_cond_iva', [])
          dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        }
      }
    },
    async get_regiones ({ commit, dispatch, state }) {
      if (state.regiones.length == 0) {
        try {
          const res = await fetch(`${config.BASE_URL}/regiones`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_regiones', data.data)
          } else {
            commit('set_regiones', [])
            dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
  
        } catch (error) {
          commit('set_regiones', [])
          dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        }
      }
    },
    async get_all_locales ({ state, commit, dispatch }) {
      if (state.all_locales.length == 0) {
        try {
          const res = await fetch(`${config.BASE_URL}/locales`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_all_locales', data.data)
          } else {
            commit('set_all_locales', [])
            dispatch('show_snackbar', {
              text: 'Fallo al obtener todos los locales: ' + data.message,
              color: 'error'
            })
          }
  
        } catch (error) {
          commit('set_all_locales', [])
          dispatch('show_snackbar', {
            text: 'Fallo al obtener todos los locales: ' + error.message,
            color: 'error'
          })
        }
      }
    },
    async get_mayoristas ({ state }, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/getMayoristas`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    // obtiene los canales habilitados
    async get_canales ({ commit, dispatch, state }) {
      if (state.canales.length == 0) {
        try {
          const res = await fetch(`${config.BASE_URL}/canales`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) {
            commit('set_canales', data.data)
          } else {
            commit('set_canales', [])
            dispatch('show_snackbar', {
              text: data.message,
              color: 'error'
            })
          }
  
        } catch (error) {
          commit('set_canales', [])
          dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        }
      }
    },
    async get_tipos_concepto_fe ({ state }) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/tiposConceptoFE`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            }
          })

          const data = await res.json()

          if (data.exito == 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async marcar_notificacion_leida ({ state }, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/marcarNotificacionLeida`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito === 1) {
            resolve(data)
          } else {
            reject(data)
          }
          
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_notificaciones ({ state }, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/notificaciones`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + JSON.parse(localStorage.getItem('token'))
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito === 1) {
            resolve(data)
          } else {
            reject(data)
          }
          
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_notificaciones_noleidas ({ dispatch, commit }) {

      await dispatch('get_notificaciones', { estado: 1 })
        .then(res => {
          commit('set_notificaciones', res.data)
        })
        .catch(() => {
          commit('set_notificaciones', [])
        })

    },
    async get_img_perfil ({ dispatch, commit, state }) {

      await dispatch('get_all_files', { path: '/imagenes_perfil/' + state.username, raiz: 1 })
        .then(res => {
          if (res.size > 0) {
            commit('set_img_perfil', res.files[0].file)
          } else {
            commit('set_img_perfil', '')
          }
        })
        .catch(() => {
          commit('set_img_perfil', '')
        })

    },
    async get_all_files ({ state }, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/getAllFiles`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito === 1) {
            resolve(data)
          } else {
            reject(data)
          }
          
        } catch (error) {
          reject(error)
        }
      })
    },
    async delete_file ({ state }, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/deleteFile`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito === 1) {
            resolve(data)
          } else {
            reject(data)
          }
          
        } catch (error) {
          reject(error)
        }
      })
    },
    async upload_file ({ state }, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/uploadFile`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito === 1) {
            resolve(data)
          } else {
            reject(data)
          }
          
        } catch (error) {
          reject(error)
        }
      })
    },
    async get_banners ({ commit, state }) {
      try {
        const res = await fetch(`${config.BASE_URL}/getBanners`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': state.long_token
          }
        })

        const data = await res.json()

        if (data.exito === 1) {
          commit('set_banners', data.banners)
        } else {
          commit('set_banners', [])
        }

      } catch (error) {
        console.error(error)
      }
    },
    async get_loc_x_nombre ({ state }, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/locXnombre`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito === 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_loc_x_cp ({ state }, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/locXcp`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito === 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_tipos_doc ({ state }) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/tiposDoc`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.long_token
            },
          })

          const data = await res.json()

          if (data.exito === 1) {
            resolve(data)
          } else {
            reject(data)
          }

        } catch (error) {
          reject(error)
        }
      })
    },
    async get_sucursales ({ commit, state }) {
      try {
        const res = await fetch(`${config.BASE_URL}/datosUser/sucursales`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': state.long_token
          }
        })
        const data = await res.json()

        if (data.exito === 1) {
          commit('set_sucursales', data.sucursales)
        } else {
          commit('set_sucursales', [])
        }

      } catch (error) {
        console.error(error)
      }
    },
    async get_locales ({ commit, state }) {
      try {
        const res = await fetch(`${config.BASE_URL}/datosUser/locales`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': state.long_token
          }
        })
        const data = await res.json()

        if (data.exito === 1) {
          commit('set_locales', data.ptos_vta)
        } else {
          commit('set_locales', [])
        }

      } catch (error) {
        console.error(error)
      }
    },
    async get_empresas ({ commit, state }) {
      try {
        const res = await fetch(`${config.BASE_URL}/datosUser/empresas`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': state.long_token
          }
        })
        const data = await res.json()

        if (data.exito === 1) {
          commit('set_empresas', data.empresas)
        } else {
          commit('set_empresas', [])
        }

      } catch (error) {
        console.error(error)
      }
    },
    show_snackbar ({ commit }, payload) {
      let snackbar = {
        value: true,
        text: payload.text,
        color: payload.color,
        timeout: 3000
      }
      if (payload.timeout) {
        snackbar.timeout = payload.timeout
      }
      commit('set_snackbar', snackbar)
    },
    loadLocalStorage({ commit, state }) {
      if (localStorage.getItem('token')) {
        commit('load_token', JSON.parse(localStorage.getItem('token')))
        if (state.token !== '') {
          commit('set_username', Decrypt(jwt.decodeToken(state.token).credenciales.usuario)) 
        }
      } else {
        localStorage.setItem('token', JSON.stringify(state.token))
      }
      commit('set_sucursales', JSON.parse(localStorage.getItem('sucursales')))
      commit('set_locales', JSON.parse(localStorage.getItem('locales')))
      commit('set_empresas', JSON.parse(localStorage.getItem('empresas')))
      commit('set_menu', JSON.parse(localStorage.getItem('menu')))
      commit('set_menu_rapido', JSON.parse(localStorage.getItem('menu_rapido')))
      commit('set_banners', JSON.parse(localStorage.getItem('banners')))
      commit('set_img_perfil', JSON.parse(localStorage.getItem('img_perfil')))
      commit('set_notificaciones', JSON.parse(localStorage.getItem('notificaciones')))
      // canales
      if (localStorage.getItem('canales')) commit('set_canales', JSON.parse(localStorage.getItem('canales')))
      else localStorage.setItem('canales', JSON.stringify(state.canales))
      // rubros
      if (localStorage.getItem('rubros')) commit('rubros/set_rubros', JSON.parse(localStorage.getItem('rubros')))
      else localStorage.setItem('rubros', JSON.stringify([]))
      // marcas
      if (localStorage.getItem('marcas')) commit('marcas/set_marcas', JSON.parse(localStorage.getItem('marcas')))
      else localStorage.setItem('marcas', JSON.stringify([]))
      // categorias
      if (localStorage.getItem('categorias')) commit('categorias/set_categorias', JSON.parse(localStorage.getItem('categorias')))
      else localStorage.setItem('categorias', JSON.stringify([]))
      // formas de pago
      if (localStorage.getItem('formas_pago')) commit('pagos/set_formas_pago', JSON.parse(localStorage.getItem('formas_pago')))
      else localStorage.setItem('formas_pago', JSON.stringify([]))
      // formas de cobro
      if (localStorage.getItem('formas_cobro')) commit('cobros/set_formas_cobro', JSON.parse(localStorage.getItem('formas_cobro')))
      else localStorage.setItem('formas_cobro', JSON.stringify([]))
      // tarjetas
      if (localStorage.getItem('tarjetas')) commit('tarjetas/set_tarjetas', JSON.parse(localStorage.getItem('tarjetas')))
      else localStorage.setItem('tarjetas', JSON.stringify([]))
      // tipos de control de stock (auditoria)
      if (localStorage.getItem('tp_control_stk')) commit('controlStock/set_tp_control_stk', JSON.parse(localStorage.getItem('tp_control_stk')))
      else localStorage.setItem('tp_control_stk', JSON.stringify([]))
      // estados de control de stock
      if (localStorage.getItem('est_control_stk')) commit('controlStock/set_est_control_stk', JSON.parse(localStorage.getItem('est_control_stk')))
      else localStorage.setItem('est_control_stk', JSON.stringify([]))
      // estados de control de stock
      if (localStorage.getItem('est_control_stk_det')) commit('controlStock/set_estados_detalle', JSON.parse(localStorage.getItem('est_control_stk_det')))
      else localStorage.setItem('est_control_stk_det', JSON.stringify([]))
      // tipos de recupero
      if (localStorage.getItem('tp_recupero')) commit('controlStock/set_tp_recupero', JSON.parse(localStorage.getItem('tp_recupero')))
      else localStorage.setItem('tp_recupero', JSON.stringify([]))
      // dias de la semana
      if (localStorage.getItem('dias_semana')) commit('genericosAcc/set_dias_semana', JSON.parse(localStorage.getItem('dias_semana')))
      else localStorage.setItem('dias_semana', JSON.stringify([]))
      // bodegas habilitadas del usuario
      if (localStorage.getItem('bodegas_user')) commit('bodegas/set_bodegas_user', JSON.parse(localStorage.getItem('bodegas_user')))
      else localStorage.setItem('bodegas_user', JSON.stringify([]))
      // bodegas origen, destino y estados de remitos
      if (localStorage.getItem('bodegasO')) commit('remitos/setBodegasOrigen', JSON.parse(localStorage.getItem('bodegasO')))
      else localStorage.setItem('bodegasO', JSON.stringify([]))
      if (localStorage.getItem('bodegasD')) commit('remitos/setBodegasDestino', JSON.parse(localStorage.getItem('bodegasD')))
      else localStorage.setItem('bodegasD', JSON.stringify([]))
      if (localStorage.getItem('estadosRemitos')) commit('remitos/setEstadosRemitos', JSON.parse(localStorage.getItem('estadosRemitos')))
      else localStorage.setItem('estadosRemitos', JSON.stringify([]))
      if (localStorage.getItem('estadosRemitosDev')) commit('remitos/setEstadosDevolucion', JSON.parse(localStorage.getItem('estadosRemitosDev')))
      else localStorage.setItem('estadosRemitosDev', JSON.stringify([]))
      // cajas habilitadas del usuario
      if (localStorage.getItem('cajas_user')) commit('caja/set_cajas_user', JSON.parse(localStorage.getItem('cajas_user')))
      else localStorage.setItem('cajas_user', JSON.stringify([]))
      // estados de la caja
      if (localStorage.getItem('est_caja')) commit('caja/set_estados_caja', JSON.parse(localStorage.getItem('est_caja')))
      else localStorage.setItem('est_caja', JSON.stringify([]))
      // gestión de stock
      if (localStorage.getItem('stock_categorias')) commit('stock/setStockCateg', JSON.parse(localStorage.getItem('stock_categorias')))
      else localStorage.setItem('stock_categorias', JSON.stringify([]))
      if (localStorage.getItem('stock_depositos')) commit('stock/setStockDepositos', JSON.parse(localStorage.getItem('stock_depositos')))
      else localStorage.setItem('stock_depositos', JSON.stringify([]))
      if (localStorage.getItem('stock_entidades')) commit('stock/setStockEntidades', JSON.parse(localStorage.getItem('stock_entidades')))
      else localStorage.setItem('stock_entidades', JSON.stringify([]))
      if (localStorage.getItem('stock_periodos')) commit('stock/setStockPeriodos', JSON.parse(localStorage.getItem('stock_periodos')))
      else localStorage.setItem('stock_periodos', JSON.stringify([]))
      if (localStorage.getItem('stock_regiones')) commit('stock/setStockRegiones', JSON.parse(localStorage.getItem('stock_regiones')))
      else localStorage.setItem('stock_regiones', JSON.stringify([]))
      if (localStorage.getItem('stock_rubros')) commit('stock/setStockRubros', JSON.parse(localStorage.getItem('stock_rubros')))
      else localStorage.setItem('stock_rubros', JSON.stringify([]))
      if (localStorage.getItem('dias_semana')) commit('set_dias_semana', JSON.parse(localStorage.getItem('dias_semana')))
      else localStorage.setItem('dias_semana', JSON.stringify([]))
    },
    async get_dias_semana({ commit, state }){
      try {
        const res = await fetch(`${config.BASE_URL}/getDiasSemana`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': state.long_token
          }
        })
        const data = await res.json()

        if (data.resultado === 1) {
          commit('set_dias_semana', data.dias)
        } else {
          commit('set_dias_semana', [])
        }

      } catch (error) {
        console.error(error)
      }
    },
  },
  modules: {
    vendedores,
    giftcards,
    caja,
    admCajas,
    objetivos,
    dycar,
    marcas,
    modelos,
    rubros,
    categorias,
    lineas,
    listas,
    proveedores,
    genericosAcc,
    genericos,
    precios,
    tarjetas,
    cuotas,
    financiaciones,
    cobros,
    pagos,
    articulos,
    bodegas,
    bancos,
    creditosDebitos,
    inventario,
    bejerman,
    facturasCompra,
    controlStock,
    controlCaja,
    movBancarios,
    penalizaciones,
    grupopy,
    remitos,
    stock,
    facturasAdmin,
    ventas,
    remitosDevolucion,
    clientes,
    acreditaciones,
    gestores,
    talonarios,
    localesStore,
    provinciasStore,
    parametros,
    laposIntegrado,
    ordenesCompra,
    cheques,
    dashboard,
    genericosServ,
    empleados,
    evaluaciones,
    promociones,
    recepciones,
    incidentes,
    archivos,
    encuestas,
    cambioDirecto,
    afip,
    conciliacionBancaria,
    contribucionFranquicia,
    conciliacionManual,
    mayoristaCobranzas,
    feriados,
    indices,
    ventasMay,
    paquetes,
    repartidores
  },
  getters: {
    is_logged(state) {
      return !jwt.isExpired(state.token)
    },
    una_empresa (state) {
      return state.empresas.length == 1
    },
    una_sucursal (state) {
      return state.sucursales.length == 1
    }
  }
})
